console.log("auth_logout.js loaded...");

import { Auth } from 'aws-amplify';

// Sign Out function
export async function signOut() {
    console.log("signOut triggered...")
    try {
        await Auth.userPool.getCurrentUser().signOut()
        window.location = '/'        
    } catch (error) {
        console.log('error signing out: ', error);
    }
}


// Event Listener for Sign Out button
