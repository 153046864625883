console.log("auth_forgot_password.js loaded...");

import { Auth } from 'aws-amplify';

// Forgot password function
export const forgotPass = async ({username}) => {    
    try {
        const { user } = await Auth.forgotPassword(username);
        console.log(user)
        alert("Password reset request sent");
        window.location = '/?auth=forgotconfirm#' + username;
    } catch (error) {
        console.log('error signing in', error);
        alert(error.message);
        window.location = './'
    }
}

// Event Listeners if user is on Forgot Password page


// Confirm New Password function
export const confirmForgotPass = async (username, code, new_password) => {    
    try {
        await Auth.forgotPasswordSubmit(username, code, new_password);        
        alert("New password confirmation sent");   
        window.location = './'     
    } catch (error) {
        console.log('error confirming new password', error);
        alert(error.message);
    }
}

// Event Listeners on the Confirm New Password page (after Forgot Password page)
