console.log("auth_signup.js loaded...");
const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
const authFunc= urlParams.get('auth');
console.log(authFunc);


import { Auth } from 'aws-amplify';

// User Sign Up function
export const signUp = async ({ email, password, checkbox}) => {
    console.log("signup triggered...");    
    const username = email;    // As username is a required field, even if we use email as the username    
    
    if (!checkbox) {  // Check if the "agree" checkbox has been checked
        console.log("Checkbox not checked.");
        alert("User has to agree to Terms of Service and Privacy Policy");
        return;
    }
    if (password.length < 6) {  // Check if the password is too short
        console.log("Password is too short");
        alert("Pasword should consist of 6 characters or longer.");
        return;
    }

    /* if (validateEmail(email)) {  // Check if the password is too short
        console.log("Enter valid email address");
        alert("Enter valid email address.");
        return;
    } */
    console.log("sending to Cognito...");
    try {
        const { user } = await Auth.signUp({
            username,
            email,
            password,
            attributes: {                
                // other custom attributes 
            }
        });
        console.log(user);
        window.location = '/?auth=confirm#' + username;
    } catch (error) {
        console.log('error signing up:', error);
        // Redirect to login page if the user already exists
        if (error.name === "UsernameExistsException") {
            alert(error.message);
            window.location = '/?auth=signup';
        }        
    }
}


// Event Listeners if user is on the Sign Up page

// Account confirmation function
export const confirmSignUp = async ({username, code}) => {    
    console.log('çonfirm');
    try {
      const {result} = await Auth.confirmSignUp(username, code);
      console.log(result);
      alert("Account created successfully");
      window.location = `/?mail=${username}&code=${code}`

    } catch (error) {
        console.log('error confirming sign up', error);
        alert(error.message);
    }
};

// Resend confrimation code function
export const resendConfirmationCode = async (username) => {
    try {
        await Auth.resendSignUp(username);
        console.log('code resent successfully');
        alert('code resent successfully');
    } catch (error) {
        console.log('error resending code: ', error);        
        alert(error.message);
    }
};

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      );
  };






