
const env='d';
console.log(env);
const version="0.1.02";
import './scss/styles.scss';


// Import just what we need

// import 'bootstrap/js/dist/alert';
//import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
//import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
import * as bootstrap from '../node_modules/bootstrap';
import "./style.css";

import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import aws_exports from "./aws-exports.js";
import { userAuthState } from './auth/auth_user';
import { signUp, confirmSignUp, resendConfirmationCode } from './auth/auth_signup';
import { signIn } from './auth/auth_login';
import { forgotPass, confirmForgotPass } from './auth/auth_forgot_password';
import { signOut } from './auth/auth_logout';

import * as XLSX from "xlsx";
const { Configuration, OpenAIApi } = require("openai");

const apiPath = 'https://cyz7bkwwhl.execute-api.us-west-2.amazonaws.com/';
const prodEnv = 'production/';
const devEnv = 'dev/';
const devApiPath =apiPath+devEnv;
let fullApiPath ='';
if (env == "p") {
    fullApiPath = apiPath+prodEnv;
} else {
    fullApiPath = apiPath+devEnv;
}
console.log(fullApiPath);


Amplify.configure(aws_exports);
//sessionCheck();
var state = 'notlogged';


import * as blog from "./blog.js";
import { promoHTML, calculatorHTML, confirmHTML, confirmpassHTML, signupHTML, resetHTML, loginHTML, aboutHTML, rulesHTML, blogHTML, blogpostHTML, blogpostshowHTML  } from "./template.js";
let wallTableDiv = '';
let buildingTableDiv = '';
const promoDisplay = document.getElementById("promo-wrapper");
const calculatorDisplay = document.getElementById("auth-content");
const authDisplay = document.getElementById("auth-form");

var headers = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}
console.log(headers);

simpleRouter();
navButtonsListeners();
toggleButtonVisibility(); 
minimizeNavButtons();

// FINISH UI


import { v4 as uuidv4 } from 'uuid';






import Chart from 'chart.js/auto';
Chart.defaults.color = '#222d4336';
Chart.defaults.borderColor = '#222d4336';






////////////////////////////////////
////////////////////////////////////
// CALCULATOR DEFAULT DATA
////////////////////////////////////

var project = [];
var projectlist='';
let building = [];
let wall = [];
let material = [];
let energy = [];
var heatingEnergy = 15;
var electricityEnergy = 10;
var heatingCoefficient = 0.349;
var electricityCoefficient = 0.778;
var electricityGWP = 0;
var heatingGWP = 0;
var electricityGWPpartial = 0;
var heatingGWPpartial = 0;
var lifecycleYears = 50;
///////
var result = {};
result.A1A2A3 = 0;
result.C3 = 0;
result.C4 = 0;
result.D = 0;



/////
var buildingGWPresult = {};
buildingGWPresult.A1A2A3 = 0;
buildingGWPresult.C3 = 0;
buildingGWPresult.C4 = 0;
buildingGWPresult.D = 0;


///////
var currentYear = 2023;
var yearsTable = [];
var yearlyGWP = [];
var yearlyGWPemb = [];
var yearlyGWPope = [];
var embodiedResult = 0;
var operationalResult = 0;
var totalResult = 0;
var buildingArea = 1000;
////////////////////////////////////

let componentResultDisplay = ''; 
let BuildingResultDisplay = '';
let BuildingResultDisplayM2 = ''; 

let ProjectsListDisplay = '';

let clearComponentButton = ''; 
let addComponentButton = ''; 
let clearMaterialButton = ''; 
let addMaterialButton = ''; 
let updateAreaButton = ''; 
let calculateEnergyButton = ''; 
let createProjectButton = '';
let loadProjectButton = '';
let saveProjectButton = '';
let deleteProjectButton = '';
let addUserMaterialToLibraryButton = '';
let clearUserMaterialButton = '';

var sladairesponsefield = '';
var sladaipromptfield = '';
let excelButton = '';
let aiButton = '';

let navEn ='';
let navPl = '';

var materialSearchInput = '';
var userMaterialSearchInput = '';

let dataButton = ''; 
var componentName = '';
var componentNameId = 1;
var componentArea = 1;
var componentsGWPtable = [];
var componentLabels = [];

var projectNameInput = '';
var projectAuthorInput = '';
var projectAddressInput = '';

var ctxChartLifecycleGWP ='';
var ChartLifecycleGWP='';
var ctxChartComponentsGWP ='';
var ChartComponentsGWP='';

var projectsListInfo = '';

////// username for database
var username = '';
////// JWT TOKEN ID HEADER

var token = '';
var userid = '';
var email = '';

var projectlist = '';


var l = "en";

var postid = ''; 
var page = ''; 


async function sessionCheck(auth) {
    
    let user = await Auth.currentSession()
    .then(data => {
        console.log("logged");
        
        username = data.getIdToken().payload.sub //username
        const id = data.getIdToken().getJwtToken();
        console.log(id);
        token = id;
        headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization" : id
            }
        
        }
        console.log(headers);
        console.log("authorized");
        if (auth == "calculator") {
            renderAuthApp();
        }
        
    })
    .catch(error => {
        console.log("not logged");
        state = 'notlogged';
    })
        
    
   
}
async function checkAuthContent(auth) {
    
    await userAuthState()
                .then(data => {
                    console.log('user is authenticated: ', data);
                    displayTopMessage(data.attributes.email);
                    email = data.attributes.email;
                    userid = data.attributes.sub;
                    state = 'logged';
                    toggleButtonVisibility();
                    sessionCheck(auth);
                    
                })
                .catch(error => {
                    console.log('user is not authenticated: ', error);
                    state = 'notlogged';
                    if (auth == "calculator") {
                        window.location = '/?auth=login';
                    }
                    if (auth == "auth") {
                        window.location = '/?auth=login';
                    }
                    
                });
                
                return state;
} 




async function startCalculatorApp() {
    
    const apiUrl = fullApiPath+"getmaterials";
    const userApiUrl = devApiPath+"getusermaterials"+"/"+username;
    
    //console.log(userApiUrl)
    //console.log(headers);
    //console.log(token);
    const response = await fetch(apiUrl, headers);
    console.log(response)
    const materialsResponse = response.materials;
    console.log(materialsResponse)
    const userMaterialsResponse = response.userMaterials;
    const responseData = await response.json();
    console.log(responseData)
    const data = responseData.materials;
    console.log(data)
    const userData = responseData.userMaterials;
    
    //const userResponse = await fetch(userApiUrl, headers);
    //const userData = await userResponse.json();
    console.log(userData)
    ProjectsListDisplay = document.getElementById("projectdata");
    
    

    wallTableDiv = document.getElementById("wall-table");
    buildingTableDiv = document.getElementById("building-table");
    componentResultDisplay = document.getElementById("componentResultDisplay");
    BuildingResultDisplay = document.getElementById("buildingResultDisplay");
    BuildingResultDisplayM2 = document.getElementById("buildingResultDisplayM2");

    

    projectNameInput = document.getElementById('project-name-input');
    projectAuthorInput = document.getElementById('author-input');
    projectAddressInput = document.getElementById('address-input');

    clearComponentButton = document.getElementById("component-clear-btn");
    addComponentButton = document.getElementById("component-add-btn");
    clearMaterialButton = document.getElementById("material-clear-btn");
    addMaterialButton = document.getElementById("material-add-btn");
    updateAreaButton = document.getElementById("calculate-button");
    calculateEnergyButton = document.getElementById("calculate-energy-button");
    dataButton = document.getElementById("data-button");
    excelButton = document.getElementById("excel-button");
    aiButton = document.getElementById("ai-button");

    addUserMaterialToLibraryButton = document.getElementById("add-material-add-btn");
    clearUserMaterialButton = document.getElementById("add-material-clear-btn")

    createProjectButton = document.getElementById("new-project-btn");
    loadProjectButton = document.getElementById("load-project-btn");
    saveProjectButton = document.getElementById("save-project-btn");
    deleteProjectButton = document.getElementById("remove-project-btn");


    sladairesponsefield = document.getElementById("slad-ai-response");
    sladaipromptfield = document.getElementById("slad-ai-prompt");
    navEn = document.getElementById("nav-en");
    navPl = document.getElementById("nav-pl");

    materialSearchInput = document.getElementById("materialFilter");
    userMaterialSearchInput = document.getElementById("userMaterialFilter");

    projectAuthorInput.value = email;
    
    checkProjectListDatabase().then(function() {showProjects(projectlist);});
    
    processMaterialLibraryData(data);
    processUserMaterialLibraryData(userData);
    addEventListenersToCalulatorButtons();
    eventCreateProject();
    
    displayTotalResult();
    
    const versionSpan = document.getElementById("version");
    versionSpan.innerHTML = version+env;
}

async function checkProjectListDatabase () {
    const apiProjectsUrl = fullApiPath+"getprojects?id="+userid;
    const responseProjects = await fetch(apiProjectsUrl, headers);
    const projectdata = await responseProjects.json();
    projectlist = projectdata.Items;
    
}

////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   RENDER FUNCTIONS

async function simpleRouter () {
    
    const authFunc = checkState();
   
    console.log(authFunc);
    if (authFunc === "login") {

        console.log("login router");
        renderLogin();
    } else if (authFunc === "forgot") {
        renderReset();
    } else if (authFunc === "signup") {
        renderSignup();
    } else if (authFunc === "confirm") {
        renderConfirm();
    } else if (authFunc === "logout") {

        signOut();
    } else if (authFunc === "forgotconfirm") {

        renderConfirmPass();
    } else if (authFunc === "calculator") {

        await checkAuthContent("calculator");
        
        
    } else if (authFunc === "rules") {
        renderRules();   
    }
    else if (authFunc === "blog") {
        
        postid =  checkId();
        page =  checkPage();
        renderBlog(page, postid);
        
        
        
    } else if (authFunc === "about") {
        await checkAuthContent();
        renderAbout();
    } else {
        await checkAuthContent();
        renderPromo();
        console.log("render promo");
    }
}


async function renderBlog(page, id) {
    //console.log(headers);
    promoDisplay.innerHTML = blogHTML;
    const blogpostsInput = document.getElementById("blogposts");
    const blogResponse = blog.checkBlogListDatabase(fullApiPath, headers);
    const blogData = await blogResponse;
    if (id !== null && id !== undefined && id !== '') {
        // Do something if the variable is not empty
        //blogpostsInput.innerHTML = blogpostshowHTML;
        blog.showBlogPost(blogData, id);
    } else if (page !== null && page !== undefined && page !== '') {
        //blogpostsInput.innerHTML = blogpostHTML;
        blog.showBlogList(blogData);
    } else {
        //blogpostsInput.innerHTML = blogpostHTML;
        blog.showBlogList(blogData);
    }
    
    
    //blog.showBlog(blogData);
    console.log(blogData);
    
    
    
}


function renderLogin() {

    authDisplay.innerHTML = loginHTML;
    if (document.querySelector("#auth-login")) {
        console.log ("on login page");
    
        document.querySelector("#form-auth-login").addEventListener("click", event => {
            event.preventDefault();
        });
    
        document.querySelector("#btnLogin").addEventListener("click", () => {
            const username = document.querySelector("#formLoginEmail").value
            const password = document.querySelector("#formLoginPassword").value
            //console.log({username, password});
            signIn({username, password});
        });
    } else {
        console.log ("not on login page");
    }
}
function renderSignup() {

    authDisplay.innerHTML = signupHTML;
    if (document.querySelector("#auth-signup")) {

        document.querySelector("#form-auth-signup").addEventListener("submit", event => {
            event.preventDefault(); // Prevent the browser from reloading on submit event.
        });
    
        document.querySelector("#btnSignUp").addEventListener("click", () => {
            const email = document.querySelector("#formSignUpEmail").value;
            const password = document.querySelector("#formSignUpPassword").value;
            const checkbox = document.querySelector("#formRules").checked;
            console.log(checkbox);
            signUp({ email, password, checkbox });
        });
    
    };
    
}
function renderReset() {

    authDisplay.innerHTML = resetHTML;
    if (document.querySelector("#auth-forgot-password")) {

        document.querySelector("#form-auth-forgot-password").addEventListener("click", event => {
            event.preventDefault();
        });
    
        document.querySelector("#btnForgot").addEventListener("click", () => {
            const username = document.querySelector("#formForgotEmail").value                
            forgotPass( {username});
        });
    
    }
}
function renderConfirm() {

    authDisplay.innerHTML = confirmHTML;
    if (document.querySelector("#auth-signup-confirm")) {

        // Populate the email address value
        let username_value = location.hash.substring(1);   
        
        const queryString = window.location.search;
        
        const urlParams = new URLSearchParams(queryString);
        
        if (urlParams.get('code')) {
            const code_value= urlParams.get('code');
            document.querySelector("#formSignUpConfirmCode").setAttribute("value", code_value);
        } else {
            const code_value= '';
            document.querySelector("#formSignUpConfirmCode").setAttribute("value", code_value);
        }
        
            
        
        
        document.querySelector("#formSignUpConfirmEmail").setAttribute("value", username_value);
        
        document.querySelector("#form-auth-signup-confirm").addEventListener("click", event => {
            event.preventDefault();
        });
    
        document.querySelector("#btnConfirm").addEventListener("click", () => {
            let username = document.querySelector("#formSignUpConfirmEmail").value
            const code = document.querySelector("#formSignUpConfirmCode").value
            console.log({username, code});
            confirmSignUp({username, code});
        });
    
        document.querySelector("#btnResend").addEventListener("click", () => {
            let username = document.querySelector("#formSignUpConfirmEmail").value
            resendConfirmationCode(username);
        });
    }
    
}
function renderConfirmPass() {

    authDisplay.innerHTML = confirmpassHTML;
    if (document.querySelector("#auth-forgot-password-confirm")) {

        // Populate the email address value
        let username_value = location.hash.substring(1);        
        document.querySelector("#formForgotConfirmEmail").setAttribute("value", username_value);
    
    
        document.querySelector("#form-auth-forgot-password-confirm").addEventListener("click", event => {
            event.preventDefault();
        });
    
        document.querySelector("#btnConfirmForgot").addEventListener("click", () => {
            const username = document.querySelector("#formForgotConfirmEmail").value
            let code = document.querySelector("#formForgotConfirmCode").value
            let password = document.querySelector("#formForgotConfirmPassword").value
            confirmForgotPass( username, code, password );        
        });
    
    }
}
function renderAbout() {
    promoDisplay.innerHTML = aboutHTML;
}
function renderPromo() {
    promoDisplay.innerHTML = promoHTML;
}
function renderRules() {
    promoDisplay.innerHTML = rulesHTML;
}

function renderAuthApp() {
    renderCalculator();
    startCalculatorApp();
}

function renderCalculator() {

    calculatorDisplay.innerHTML = calculatorHTML;
     
}

////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   UI FUNCTIONS
function minimizeNavButtons () {
    const navButtons = document.getElementsByClassName("nav-button");
    //console.log(navButtons);
    [].forEach.call(navButtons, function (button) {
        button.addEventListener("click", minimizeContainer)
    });
    
    
}

function minimizeContainer() {
    if (this.innerHTML === "-") {
    this.parentNode.parentNode.children[1].style.display='none';
    this.innerHTML = "+";
    } else {
        this.parentNode.parentNode.children[1].style.display='block';
        this.innerHTML = "-";
    }
}

function addEventListenersToCalulatorButtons() {

    clearComponentButton.addEventListener("click", clearComponent);
    addComponentButton.addEventListener("click", addComponent);
    updateAreaButton.addEventListener("click", updateBuildingArea);
    calculateEnergyButton.addEventListener("click", updateEnergyData);
    dataButton.addEventListener("click", updateDataViz);

    createProjectButton.addEventListener("click", eventCreateProject);
    loadProjectButton.addEventListener("click", eventLoadProject);
    saveProjectButton.addEventListener("click", eventSaveProject);
    deleteProjectButton.addEventListener("click", eventDeleteProject);

    addUserMaterialToLibraryButton.addEventListener("click", eventAddUserMaterialToLibrary);
    clearUserMaterialButton.addEventListener("click", eventClearUserMaterial);

    excelButton.addEventListener("click", eventGenerateEXCEL);
    aiButton.addEventListener("click", eventAskAI);

    navEn.addEventListener("click", setEn);
    navPl.addEventListener("click", setPl);
    
    materialSearchInput.addEventListener('keyup', materialFilterSearch);
    userMaterialSearchInput.addEventListener('keyup', userMaterialFilterSearch);
}
/////////////////////////////////////////
// LANG FUNCTIONS

function setPl () {
    setLang('pl');

}
function setEn () {
    setLang('en');
    
}

function setLang(lang) {
    if (lang=="pl") {
        l = "pl";
    }
    else {
        l = "en";
    }
}

function loadLang () {
    if (l=="pl") {
        //import {lang} from "/lang.pl.js";
    }
    else {
        //l = "en";
    }
}
/////////////////////////////////////////

function eventAddUserMaterialToLibrary () {
    var materialData = {};

    // Read data from inputs
    materialData.private = document.getElementById("privateCheck").checked;
    materialData.name = document.getElementById("add-material-info-name").value;
    materialData.longName = document.getElementById("add-material-info-longname").value;
    materialData.unit = document.getElementById("add-material-info-unit").value;
    materialData.gwp = {
        A1A2A3: document.getElementById("add-material-info-gwpa1a3").value,
        C3: document.getElementById("add-material-info-gwpc3").value,
        C4: document.getElementById("add-material-info-gwpc4").value,
        D: document.getElementById("add-material-info-gwpd").value,
        A4: document.getElementById("add-material-info-gwpa4").value,
        A5: document.getElementById("add-material-info-gwpa5").value,
        B1: document.getElementById("add-material-info-gwpb1").value,
        B2: 0,
        B3: 0,
        B4: 0,
        B5: 0,
        B6: 0,
        B7: 0,
        C1: document.getElementById("add-material-info-gwpc1").value,
        C2: document.getElementById("add-material-info-gwpc2").value,


    };
    materialData.comment = document.getElementById("add-material-info-comment").value;
    materialData.EPDsource = document.getElementById("add-material-info-EPD-source").value;
    materialData.EPDlink = document.getElementById("add-material-info-EPD-link").value; //
    materialData.producer = 'user / ' + document.getElementById("add-material-info-producer").value;
    materialData.tags = document.getElementById("add-material-info-tags").value; //
    materialData.densitykgm3 = document.getElementById("add-material-info-density").value; //
    materialData.defaultAmount = document.getElementById("add-material-info-defamount").value; //
    materialData.dataValidityYear = document.getElementById("add-material-info-validityyear").value;
    materialData.user_id = username
    materialData.id = uuidv4();


    insertDataIntoDynamoDB(materialData)
    console.log(materialData);
}



// Function to insert data into DynamoDB
async function insertDataIntoDynamoDB(materialData) {
    const apiPostProjectsUrl =fullApiPath+"/putproject";
    const save = { materialData: materialData};
    if (save.materialData) {
        console.log(save)
    }
    console.log(JSON.stringify(save))
    const tosave = JSON.stringify(save)
    const response = await fetch(apiPostProjectsUrl, {method: 'POST', body: tosave},headers);
    console.log(response);
    
    if (!response.ok) {
        
        
        showModal("error");
    } else {
        
        //deleteProjectButton.style.display = "inline";
        showModal("usermaterialsaved");
        startCalculatorApp();
    }
}


function eventClearUserMaterial () {

}
//////////////////////////////////////
//
// USER LIBRARY

function processUserMaterialLibraryData (data) {
    //console.log(data);
    //const materialsArr = Object.keys(data);
    console.log(username)
    const filteredData = data.filter(item => item.user_id === username);
    const materialsTableDiv = document.getElementById("user-materials-table");
    materialsTableDiv.innerHTML = '';
    filteredData.forEach(function (el) {
        const id = el.id; // 1
        const name = el.name; // Concrete
        let unit = ''
        if (el.unit == 'qm')
        {
            unit = 'm2'
        }
        else
        {
            unit = el.unit; // m3
        }
        
        const gwpkgA1A2A3 = el.gwpkgA1A2A3; // 324
        const defaultAmount = el.defaultAmount;
        const longname = el.longName;
        const comment = el.comment;
        const epdsource = el.EPDsource;
        const epdlink = el.EPDlink;
        const producer = el.producer;
        const tags = el.tags;
        //console.log(el.gwp);
        const gwp = JSON.parse(JSON.stringify(el.gwp));

        

        
        //console.log(el);
        showMaterialRow(id,name,unit,gwpkgA1A2A3,defaultAmount, longname, comment, epdsource, epdlink, producer, materialsTableDiv, gwp, tags, 'usermat');
        
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   PROCESS INITIAL DATA FUNCTIONS
function processMaterialLibraryData (data) {
    console.log(data);
    //const materialsArr = Object.keys(data);
    const materialsTableDiv = document.getElementById("materials-table");
    data.forEach(function (el) {
        const id = el.id; // 1
        const name = el.name; // Concrete
        let unit = ''
        if (el.unit == 'qm')
        {
            unit = 'm2'
        }
        else
        {
            unit = el.unit; // m3
        }
        
        const gwpkgA1A2A3 = el.gwpkgA1A2A3; // 324
        const defaultAmount = el.defaultAmount;
        const longname = el.longName;
        const comment = el.comment;
        const epdsource = el.EPDsource;
        const epdlink = el.EPDlink;
        const producer = el.producer;
        const tags = el.tags;
        //console.log(el.gwp);
        const gwp = JSON.parse(JSON.stringify(el.gwp));

        

        
        //console.log(el);
        showMaterialRow(id,name,unit,gwpkgA1A2A3,defaultAmount, longname, comment, epdsource, epdlink, producer, materialsTableDiv, gwp, tags, 'tablemat');
        
    })
}

function showMaterialRow (id,name,unit,gwpkgA1A2A3,defaultAmount, longname, comment, epdsource, epdlink, producer, materialsTableDiv, gwp, tags, tabletype) {
    const el = document.createElement("div");
    el.addEventListener("click", clickOnMaterialLibrary);
    el.classList.add(tabletype +"s-table-row");
    el.classList.add("materials-table-row");
    el.data = {
        id:id,
        name:name,
        unit:unit,
        gwpkgA1A2A3: gwpkgA1A2A3,
        defaultAmount: defaultAmount,
        longname:longname,
        comment: comment,
        epdsource: epdsource,
        epdlink: epdlink,
        producer: producer,
        gwp: gwp,
        tags: tags,

    };
    el.innerHTML = /*html*/`
        <div class="container">
            <div class="material-row row ${tabletype}-row ">
                <div class="col-12 col-sm-6">
                    <div class="row">
                        <div class="col-4 ${tabletype}-row-search material-row-search"> ${name} </div>
                        <div class="col-4  text-end"> <b>${roundGWP(gwp.A1A2A3)}</b> </div>
                        <div class="col-4 text-end"> <b>${roundGWP(gwp.C3)}</b>  </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="row">
                        
                        <div class="col-4 text-end">  <b>${roundGWP(gwp.C4)}</b>  </div>
                        <div class="col-4 text-end"> <b>${roundGWP(gwp.D)}</b> </div>
                        <div class="col-4"> <small>kgCO2/${unit}</small> </div>
                        <div class="${tabletype}-row-tags-search material-row-tags-search" style="display: none;"> ${tags} </div>
                    </div> 
                </div> 
            </div>    
        </div>  
    `;

    materialsTableDiv.append(el);
}
///////////////////////////////
// AI
function describeBuilding(building) {
    let description = '';
  
    // Loop through each row of 'building'
    for (let i = 0; i < building.length; i++) {
      const component = building[i].component;
      description += `${building[i].componentamount} ${building[i].componentunit} of ${building[i].componentname}, and layers: `;
      // Loop through each row of 'component'
      for (let j = 0; j < component.length; j++) {
        const layername = component[j].layername;
        description += `${component[j].layerthickness} ${component[j].layerunit} of ${layername}, with GWP[kgCO2]: A1A3 ${component[j].layergwp.A1A2A3}, C3 ${component[j].layergwp.C3}, C4 ${component[j].layergwp.C4}  `;
      }
      description += `.`
    }
  
    return description;
  }

async function eventAskAI2 () {

}

async function eventAskAI () {
    //console.log(building);
    //console.log(describeBuilding(building));
    var description = '';
    description = JSON.stringify(describeBuilding(building));
    const apiAIUrl = fullApiPath+"askai";
    const secondPart = generatePrompt();
    const ask = secondPart + description ;
    sladaipromptfield.innerHTML = "Prompt: " + ask;
    const question = { "question": ask};
    const jsonQuestion = JSON.stringify(question);
    const jsonBody = JSON.stringify({body : question});
    //console.log(jsonQuestion);
    sladairesponsefield.innerHTML = "SLAD.AI is analyzing Your design. Please wait...";
    const response = await fetch(apiAIUrl, {method: 'POST', body: jsonQuestion}, headers);
    console.log(response);
    var body = await response.text();
    console.log(body);
    
    sladairesponsefield.innerHTML = body;
}

function generatePrompt () {
    const rand = getRandomInt(5);
    console.log(rand);
        if (rand == 0) {
            return " These are building layers. Select worst choices according to the GWP - carbon footprint of the materials, and suggest better choices to lower carbon footprint.";
            
        } else if (rand == 1) {
            return " This is a building description. Analyze material choices and suggest which materials should be changed to reduce carbon footprint.";
        } else if (rand == 2) {
            return " Building is constructed out of these materials. Propose better choice of materials for lower carbon footprint and greenhouse gas emissions.";
        } else if (rand == 3) {
            return " The building material layers are described here. What can the architect change to make the carbon footprint lower?";
        } else if (rand == 4) {
            return " Suggest better choices of materials to lower carbon footprint. Estimate how much will these changes lower the carbon footprint.";
        }else {
            return " Calculate the carbon footprint of this building and suggest better material choices.";
        }
    
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
///////////////////////////////////////
// SEARCH MATERIALS


function roundGWP (num) {
    //console.log(num);

    let num2 = Math.round(num*100)/100;
    //console.log(num2);
    return num2;
}

function materialFilterSearch ()  {
    const input = document.querySelector("#materialFilter");
    const materialRows = document.getElementsByClassName("tablemat-row");
    let filter = input.value.toLowerCase();
    for (let i = 0; i < materialRows.length; i++) {
        let row = materialRows[i].querySelector(".tablemat-row-search");
        let rowtags = materialRows[i].querySelector(".tablemat-row-tags-search");
        if (row.textContent.toLowerCase().indexOf(filter) > -1 || rowtags.textContent.toLowerCase().indexOf(filter) > -1) {
            console.log(row);
            row.parentElement.parentElement.parentElement.parentElement.parentElement.style.display = "flex";            
        } else {
            row.parentElement.parentElement.parentElement.parentElement.parentElement.style.display = "none";
        }        
    }
    console.log(filter);
}
function userMaterialFilterSearch ()  {
    const input = document.querySelector("#userMaterialFilter");
    const materialRows = document.getElementsByClassName("usermat-row");
    let filter = input.value.toLowerCase();
    for (let i = 0; i < materialRows.length; i++) {
        let row = materialRows[i].querySelector(".usermat-row-search");
        let rowtags = materialRows[i].querySelector(".usermat-row-tags-search");
        if (row.textContent.toLowerCase().indexOf(filter) > -1 || rowtags.textContent.toLowerCase().indexOf(filter) > -1) {
            console.log(row);
            row.parentElement.parentElement.parentElement.parentElement.parentElement.style.display = "flex";            
        } else {
            row.parentElement.parentElement.parentElement.parentElement.parentElement.style.display = "none";
        }        
    }
    console.log(filter);
}



///////////////////////
// EXPORT


function generateAsExcel(data) {
    try {
      
        var wb = XLSX.utils.book_new();
        
        
        
        
        wb.Props = {
            Title: "SLAD.AI results",
            Subject: "Carbon Footprint Calculation",
            Author: "SLAD.AI",
        };
        wb.SheetNames.push("Components_GWP_Table");
        //var ws = XLSX.utils.json_to_sheet(data);
        var ws = XLSX.utils.aoa_to_sheet(data);
        console.log(data);
        console.log(ws);
        wb.Sheets["Components_GWP_Table"] = ws;

        console.log(wb);
        let res = XLSX.writeFile(wb, "ComponentsGWP.xlsx",{ bookType: "xlsx", type: "array" });
        console.log(`${res.byteLength} bytes generated`);
    } catch (err) {
        console.log("Error:", err);
    }
}
  


function eventGenerateEXCEL () {
    
    var column_0 = componentLabels;
    column_0.unshift("name");
    console.log('generate file');
    var array = componentsGWPtable;
    
    
    
    var inputdata = componentsGWPtable;
    const arrays = [];
      
      // Iterate over the dictionaries and push an array for each dictionary
    for (const name in inputdata) {
        const array = [name].concat(inputdata[name]);
        arrays.push(array);
    }
      
    console.log(arrays);
    const joinedArray = [column_0].concat(arrays);

    console.log(joinedArray);   
    //output = array[0].map((_, colIndex) => array.map(row => row[colIndex])); //transpose
    generateAsExcel(joinedArray);
    

    
}


/////////////////////////////////////////////////////////////////////////////////
/// PROJECT MANAGEMENT
function showProjects(projDatas) {
    const projMsg = document.getElementById("project-msg");   
    if (projDatas.length > 0) {       
        let inside = `
        <select class="form-control" name="features" id="projectdropdown">
        
        </select>

        `

        ProjectsListDisplay.innerHTML = inside;
        loadProjectButton.style.display = "inline";
        
        const ProjectsListDisplayDropdown = document.getElementById("projectdropdown");

        var i = 0;
        projDatas.forEach(function (el) {
            
            const name = el.projectname;
            const dropdownprojectuuid = el.projectuuid;
            ProjectsListDisplayDropdown.add(new Option(name, dropdownprojectuuid));
            
            //addProjectDropdownRow(name, ProjectsListDisplayDropdown,i);
            i++;
            projMsg.innerHTML = "A new project has been created. Continue working on a blank project, or load a previous one from the dropdown below. Remember to click <b>Save project</b> before You close the window.";
        }) 
    } else {
        loadProjectButton.style.display = "none";
        deleteProjectButton.style.display = "none";
    }
}

function eventCreateProject () {
    console.log("create project");
    console.log(document.getElementById('project-name-input'));
    console.log(document.getElementById('project-name-input').value);
    var projectname = document.getElementById('project-name-input').value;
    var author = document.getElementById('author-input').value;
    var address = document.getElementById('address-input').value;
    /* let projectname = projectNameInput.value;
    let author = projectAuthorInput.value;
    let address = projectAddressInput.value; */
    console.log(projectname);
    console.log(author);
    console.log(address);
    createProject(projectname, author, address);
}

function createProject(name, author, address) {
    project ='';
    building = [];
    wall =[];
    project = {
        projectname : name,
        author : author,
        projectaddress : address,
        projectuuid : uuidv4(),
        building : building,
        username : userid,
        buildingArea : buildingArea,
        energy: energy,
        lifecycleYears: lifecycleYears,
        projectstatus: "active",
    };

    console.log("Project:");
    console.log(project);
}


function eventLoadProject () {
    const selectProject = document.getElementById("projectdropdown");
    const selectOptionIndex = selectProject.selectedIndex;
    const selectedOptionValue = selectProject[selectOptionIndex].value;
    console.log(selectedOptionValue);
    loadProject(selectedOptionValue);
    displayLoadedProject();
}

function loadProject (projectuuid) {
    const loadedProject = filterProject(projectlist,projectuuid);
    const selectedProject = loadedProject[0];
    console.log(loadedProject);
    console.log(selectedProject);
    console.log(JSON.parse(selectedProject.building));
    selectedProject.building = JSON.parse(selectedProject.building);
    selectedProject.energy = JSON.parse(selectedProject.energy);
    project = selectedProject;
    lifecycleYears = project.lifecycleYears;
    buildingArea = project.buildingArea;
    building = project.building;
    energy = project.energy;
    updateEnergyInputs();
    updateBuildingInput();
    console.log(lifecycleYears);
    console.log(project);
    console.log(building);
    showModal("projectloaded");
    deleteProjectButton.style.display = "inline";
    updateEnergyData ()
    
    return selectedProject;
    
}

function filterProject(datalist, x){
    return datalist.filter(function(item){
        return item.projectuuid == x;
    })
}

function getNumericalInput(idname) {
    const input = document.getElementById(idname)
    return input;
}
function getNumInputValue(input) {
    let val = input.value
    console.log(val)
    let value = val.toString().replace(',', '.')
    console.log(parseFloat(value))
    console.log(isNumber(value));
    if (isNaN(value)) {
        return 0;
    }
    else {
        return parseFloat(value);
        
    }
}

function isNumber(value) {
    return typeof value === 'number';
  }

function updateBuildingInput() {
    console.log(buildingAreaInput)
    //const buildingareainput = getNumericalInput(buildingAreaInput);
    console.log('area');
    console.log(buildingArea);
    //console.log(buildingareainput)
    buildingAreaInput.value = project.buildingArea;
    
}

function displayLoadedProject(){
    projectNameInput.value = project.projectname;
    projectAuthorInput.value = project.author;
    projectAddressInput.value = project.projectaddress;
    showBuilding();
}

function eventDeleteProject() {
    showModal("deleteproject");
}

async function deleteProject() {
    updateAll();
    var datas = project;
    console.log("Project to be saved");
    console.log(project);
    
    datas.building = JSON.stringify(datas.building);
    datas.energy = JSON.stringify(datas.energy);
    datas.projectstatus = "deleted";
    console.log("Data to be saved");
    console.log(datas);
    console.log("Stringified data to be saved");
    const todelete = JSON.stringify(datas);
    console.log(todelete);


    const apiPostProjectsUrl = fullApiPath+"putproject?id="+userid;
    const response = await fetch(apiPostProjectsUrl, {method: 'POST', body: todelete},headers);
    console.log(response);
    checkProjectListDatabase().then(function() {showProjects(projectlist)});


    if (!response.ok) {
        
        
        showModal("error");
    } else {
        
        
        showModal("deleted");
        eventCreateProject();
    }
    
    
}


function eventSaveProject() {
    saveProject();
    
}


async function saveProject() {
    updateAll();
    project.buildingArea = buildingArea;
    var datas = project;
    console.log("Project to be saved");
    console.log(project);
    
    datas.building = JSON.stringify(datas.building);
    datas.energy = JSON.stringify(datas.energy);
    console.log("Data to be saved");
    console.log(datas);
    console.log("Stringified data to be saved");
    const save = JSON.stringify(datas);
    console.log(save);
    console.log(headers);

    const apiPostProjectsUrl =fullApiPath+"putproject?id="+userid;
    const response = await fetch(apiPostProjectsUrl, {method: 'POST', body: save},headers);
    console.log(response);
    checkProjectListDatabase().then(function() {showProjects(projectlist)});
    if (!response.ok) {
        
        
        showModal("error");
    } else {
        
        deleteProjectButton.style.display = "inline";
        showModal("projectsaved");
    }
    
}
//////////////////////////////////////////////////////////////////////


function showModal(option) {
    const modalDIV = document.getElementById("popup");
    var msg1 = "";
    var msg2 = "";
    var msg3 = 'OK';
    var showleftbutton = `style="display: none"`;
    var rightbuttonmode = "primary";
    
    console.log(modalDIV);
    if (option == 'deleteproject'){
        msg1 = 'Do you really want to delete the loaded project?';
        msg2 = `Loaded project: <b>${project.projectname}</b>, by ${project.author}.`;
        rightbuttonmode = "danger";
        showleftbutton = `style="display: inline"`;
        msg3 = 'Delete project';
        
    } else if (option == 'projectsaved') {
        msg1 = 'Project has been saved successfully!';
        msg2 = `Saved project: <b>${project.projectname}</b>, by ${project.author}.`;
        
        
    } else if (option == 'projectloaded') {
        msg1 = 'Project has been loaded successfully!';
        msg2 = `Loaded project: <b>${project.projectname}</b>, by ${project.author}.`;
        
        
    } else if (option == 'deleted') {
        msg1 = 'Project has been removed from your account';
        msg2 = `An empty project has been created.`;
        
        
    } else if (option == 'error') {
        msg1 = 'An error has occured!';
        msg2 = `Please try again or contact support.`;

    } else if (option == 'usermaterialsaved') {
        msg1 = 'User material has been saved!';
        msg2 = ``;
        
          
        
    } else {
        msg1 = 'Please confirm';
        msg2 = ``;
        msg3 = 'Confirm';
    }
    var popupHTML = /*html*/`
    <!-- Modal -->
            <div class="modal fade" id="popupModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">${msg1}</h5>
                    </div>
                    <div class="modal-body">
                     ${msg2}
                    </div>
                    <div class="modal-footer">
                    <button id="modal-cancel" type="button" class="btn btn-info" data-dismiss="modal" ${showleftbutton}>Cancel</button>
                    <button id="modal-confirm" type="button" class="btn btn-${rightbuttonmode}">${msg3}</button>
                    </div>
                </div>
                </div>
            </div>
    `

    modalDIV.innerHTML = popupHTML;
    const mymodal = new bootstrap.Modal("#popupModal");
    mymodal.show();
    const modalcancelbutton = document.getElementById("modal-cancel");
    const modalconfirmbutton = document.getElementById("modal-confirm");
    modalcancelbutton.addEventListener("click", function () {
        mymodal.hide();
        modalDIV.innerHTML = '';
    });
    modalconfirmbutton.addEventListener("click", function () {
        mymodal.hide();
        modalDIV.innerHTML = '';
        if (option == 'deleteproject') {
            deleteProject(project.uuid);
        }
    });
}

//////////////////////////////////////////////////////////////////////
function updateAll() {
    updateEnergyData();
    updateProject();
    recalculateResults();
    displayResults();

}

function recalculateResults () {
    calculateGWP();
    calculateEnergyGWP();
    calculateBuildingGWP();

}

function displayResults () {

}


function updateProject() {
    project.lifecycleYears = lifecycleYears;
    project.projectname = projectNameInput.value;
    project.author = projectAuthorInput.value;
    project.projectaddress = projectAddressInput.value;
    project.building = building;
    project.energy = energy;
    
}

//////////////////////////////////////////////////////////////////////
function showWall () {
    console.log(wall);
    wallTableDiv.innerHTML = "";
    wall.forEach(function (walllayer) {

        
        showWallRow (walllayer);
        
    })
    calculateGWP();

}
function showWallRow (walllayer) {
    const el = document.createElement("div");
    el.classList.add("wall-table-row");
    //console.log(el);
    //el.addEventListener("click", nomodifyLayer);
    el.id = walllayer.layerid;
    el.thickness = walllayer.layerthickness;
    const element = createWallRow(el, walllayer);
    
    
    wallTableDiv.append(element);
    
    const okbtnid = "ok-btn-"+walllayer.layerid;
    const okbtn = document.getElementById(okbtnid);
    const rmvbtnid = "rmv-btn-"+walllayer.layerid;
    const rmvbtn = document.getElementById(rmvbtnid);
    okbtn.addEventListener("click", updateThickness);
    rmvbtn.addEventListener("click", removeWallRow);
}

function createWallRow(el, walllayer) {
    
    let embodiedWall = Number(walllayer.layergwp.A1A2A3)+Number(walllayer.layergwp.C3)+Number(walllayer.layergwp.C4);
    el.innerHTML = `
        <div class="row">
                <div class="col-4 col-sm-2">  ${walllayer.layername} </div>
                <div class="col-4 col-sm-2"><input id="thickness-${walllayer.layerid}" class="form-control form-control-sm form-inline" type="text" value = "${walllayer.layerthickness}"></div>
                <div class="col-4 col-sm-2 text-end"><small>${walllayer.layerunit}/m2</small> <button id="ok-btn-${walllayer.layerid}" type="button" class="btn btn-warning btn-sm text-white"><i class="fa-solid fa-rotate"></i></button> </div>
                <div class="col-4 col-sm-2"> x <b>${Math.round(embodiedWall*100)/100}</b> <small>kgCO2/${walllayer.layerunit}</small> </div>
                <div class="col-4 col-sm-2 text-end" id="wallRowResult-${walllayer.layerid}"> = <b>${Math.round(embodiedWall*walllayer.layerthickness*100)/100}</b> <small>kgCO2/m2</small> </div>
                <div class="col-4 col-sm-2 text-end"> <button id="rmv-btn-${walllayer.layerid}" type="button" class="btn btn-danger btn-sm text-white"><i class="fa-solid fa-trash-can"></i></button></div>
        </div>
        `;
    return el;
}

function recreateWall (localid) {

    console.log(localid);
    console.log(building);
    console.log(building[localid].component);
    wall = building[localid].component;
    console.log("wall");
    console.log(wall);
    componentArea =   document.getElementById("component-area-input").value = building[localid].componentamount;
    componentArea = String(componentArea).replace(',', '.');
    showWall();
    const componentNameDisplay = document.getElementById("component-name-input")
    componentNameDisplay.value = building[localid].componentname;



}

function writeWallRow(walllayer) {
    return `
    
    <div class="row">
            
            <div class="col-8 col-sm-4">  ${walllayer.layername} </div>
            <div class="col-4 col-sm-2"> ${walllayer.layerthickness} ${walllayer.layerunit}/m2 </div>
            <div class="col-2 col-sm-2"> </div>
            <div class="col-6 col-sm-4" id="wallRowResult-${walllayer.layerid}"> <b>${Math.round(walllayer.layergwp.A1A2A3*walllayer.layerthickness*100)/100}</b> <small>kgCO2/m2</small> </div>
            
    </div>
    `;
}
function removeWallRow() {
    var layerid = this.parentElement.parentElement.parentElement.id;
    
    console.log(wall);
    wall.splice(layerid, 1);
    this.parentElement.parentElement.parentElement.remove();
    
    
    console.log(wall);
    calculateGWP ();
    
}
 
function updateThickness () {
    console.log(this.parentElement.parentElement.parentElement.id);
    const thicknessinputid= "thickness-"+this.parentElement.parentElement.parentElement.id;
    const thicknessinput = getNumericalInput(thicknessinputid);
    
    const wallRowResultId = "wallRowResult-"+this.parentElement.parentElement.parentElement.id;
    const wallRowResult = document.getElementById(wallRowResultId);
    //let embodiedThis = wall[this.parentElement.parentElement.parentElement.id].layergwp.A1A2A3 + wall[this.parentElement.parentElement.parentElement.id].layergwp.C3 + wall[this.parentElement.parentElement.parentElement.id].layergwp.C4;
    const index = wall.findIndex(wall => wall.layerid === this.parentElement.parentElement.parentElement.id);
    console.log(wall.layerid);
    console.log(this.parentElement.parentElement.parentElement.id);
    console.log(index);
    console.log(wall[index]);
    wall[index].layerthickness =  getNumInputValue(thicknessinput);
    console.log(Math.round((wall[index].layergwp.A1A2A3+wall[index].layergwp.C3+wall[index].layergwp.C4)*wall[index].layerthickness*100)/100);
    console.log((parseFloat(wall[index].layergwp.A1A2A3)+parseFloat(wall[index].layergwp.C3)+parseFloat(wall[index].layergwp.C4)));
    console.log(wall[index].layerthickness);
    wallRowResult.innerHTML = `
                            <div id="wallRowResult-${this.parentElement.parentElement.parentElement.id}"> = <b>${Math.round((parseFloat(wall[index].layergwp.A1A2A3)+parseFloat(wall[index].layergwp.C3)+parseFloat(wall[index].layergwp.C4))*wall[index].layerthickness*100)/100}</b> <small>kgCO2/m2</small> </div>
    `

    calculateGWP ();
}


function showBuilding () {
    building = project.building;
    console.log(building);
    
    buildingTableDiv.innerHTML = "";
    //[0]
    building.forEach(function (buildingcomponent) {

        
        showBuildingRow (buildingcomponent);
        const areaokbtnid = "area-ok-btn-"+buildingcomponent.componentid;
        //console.log(areaokbtnid);
        const areaokbtn = document.getElementById(areaokbtnid);
        areaokbtn.addEventListener("click", updateArea);
        
    })

    
    calculateBuildingGWP();
    
}

function showBuildingRow (component) {
    const el = document.createElement("div");
    el.classList.add("building-table-row");
    console.log(el);
    console.log(component);
    
    

    el.id = component.componentid;
    el.amount = component.componentamount;
    el.componentgwp = component.componentgwp;
    let subel = '';
    let layer = ''
    console.log(component.component);
    
    component.component.forEach(function (materialLayer) {
        layer = writeWallRow(materialLayer);
        console.log(layer);
        subel = subel + layer;
    });
    console.log(subel);

    el.innerHTML = `
                        
            <div class="row">
                <div class="col-4 col-sm-2"> ${component.componentname} </div>
                <div class="col-4 col-sm-2"><input class="form-control" id="area-input-${component.componentid}" type="text" value = "${component.componentamount}"></div>
                <div class="col-4 col-sm-2"> <small>m2</small> <button id="area-ok-btn-${component.componentid}" type="button" class="btn btn-warning btn-sm text-white"><i class="fa-solid fa-rotate"></i></button></div>
                <div class="col-4 col-sm-2"> x <b>${Math.round((component.componentgwp.A1A2A3+component.componentgwp.C3+component.componentgwp.C4)*100)/100}</b> <small>kgCO2/${component.componentunit}</small> </div>
                <div class="col-4 col-sm-2" id="buildingRowResult-${component.componentid}"> = <b>${Math.round((component.componentgwp.A1A2A3+component.componentgwp.C3+component.componentgwp.C4) * component.componentamount*100)/100}</b> <small>kgCO2</small> </div>
                <div class="col-4 col-sm-2">
                <button id="edit-component-btn-${component.componentid}" type="button" class="btn btn-info btn-sm text-white"><i class="fa-solid fa-pen-to-square"></i></button>
                <button id="clear-component-btn-${component.componentid}" type="button" class="btn btn-danger btn-sm text-white"><i class="fa-solid fa-trash-can"></i></button>
                
                </div>
            </div></div><div class = "building-table-row">
                `+subel;
    
   buildingTableDiv.append(el);
   const recreateWallButton = document.getElementById(`edit-component-btn-${component.componentid}`);
   const clearWallButton = document.getElementById(`clear-component-btn-${component.componentid}`);
   
   recreateWallButton.addEventListener("click",function () {
    recreateWall(component.componentid);
    el.remove();
    delete building[el.id];

    calculateBuildingGWP();
  });
  clearWallButton.addEventListener("click",function () {
    
    el.remove();
    
    delete building[el.id];
    calculateBuildingGWP();
  });
  console.log(building);
  console.log(component.componentid);
  console.log(building[component.componentid]);
  
}




function updateArea () {
    const areainputid= "area-input-"+this.parentElement.parentElement.parentElement.id;
    console.log(this.parentElement.parentElement.parentElement.id);
    const areainput = document.getElementById(areainputid);
    console.log(areainput);
    const buildingRowResultId = "buildingRowResult-"+this.parentElement.parentElement.parentElement.id;
    const buildingRowResult = document.getElementById(buildingRowResultId);

    building[this.parentElement.parentElement.parentElement.id].componentamount = getNumInputValue(areainput);
    var compid = this.parentElement.parentElement.parentElement.id;
    var thiscomp = building[this.parentElement.parentElement.parentElement.id];   

    buildingRowResult.innerHTML = `
    <div id="buildingRowResult-${compid}"> = <b>${Math.round((thiscomp.componentgwp.A1A2A3+thiscomp.componentgwp.C3+thiscomp.componentgwp.C4) * thiscomp.componentamount*100)/100}</b> <small>kgCO2</small> </div>`
    calculateBuildingGWP();
}

function updateBuildingArea () {

    const buildingareainput = document.getElementById("buildingAreaInput");
    buildingArea = getNumInputValue(buildingareainput);
    calculateBuildingGWP();
}


//// STARTAPP FUNCTIONS



//// CLICK FUNCTIONS
function clickOnMaterialLibrary () {
    showMaterialInfo(this);
    
}


//// CALCULATE FUNCTIONS 

function calculateGWP () {

    
    result.A1A2A3 = 0;
    result.C3 = 0;
    result.C4 = 0;
    result.D = 0;
    wall.forEach(function (el){
        result.A1A2A3 += el.layerthickness * el.layergwp.A1A2A3;
        result.C3 += el.layerthickness * el.layergwp.C3;
        result.C4 += el.layerthickness * el.layergwp.C4;
        result.D += el.layerthickness * el.layergwp.D;
        
    })
    console.log(result);
    updateResult ();
}



function calculateBuildingGWP () {
    
    componentsGWPtable = [];
    componentsGWPtable.A1A2A3=[];
    componentsGWPtable.C3=[];
    componentsGWPtable.C4=[];
    componentsGWPtable.D=[];
    buildingGWPresult = {};
    console.log(buildingGWPresult);
    buildingGWPresult.A1A2A3 = 0;
    buildingGWPresult.C3 = 0;
    buildingGWPresult.C4 = 0;
    buildingGWPresult.D = 0;
    componentLabels = [];
    console.log(buildingGWPresult);
    console.log(building);
    //[0]
    building.forEach(function (bldngcomponent){

        componentLabels.push(bldngcomponent.componentname);

        console.log(bldngcomponent);

        bldngcomponent.component.forEach(function (componentlayer){
            //console.log(componentlayer.layergwp);
            // CHANGE TO FULL ARRAY CALCULATION
        });
        
        buildingGWPresult.A1A2A3 += bldngcomponent.componentamount * bldngcomponent.componentgwp.A1A2A3;
        componentsGWPtable.A1A2A3.push(bldngcomponent.componentamount * bldngcomponent.componentgwp.A1A2A3);
        buildingGWPresult.C3 += bldngcomponent.componentamount * bldngcomponent.componentgwp.C3;
        componentsGWPtable.C3.push(bldngcomponent.componentamount * bldngcomponent.componentgwp.C3);
        buildingGWPresult.C4 += bldngcomponent.componentamount * bldngcomponent.componentgwp.C4;
        componentsGWPtable.C4.push(bldngcomponent.componentamount * bldngcomponent.componentgwp.C4);
        buildingGWPresult.D += bldngcomponent.componentamount * bldngcomponent.componentgwp.D;
        componentsGWPtable.D.push(bldngcomponent.componentamount * bldngcomponent.componentgwp.D);
        
        
    });
    console.log(buildingGWPresult);
    updateBuildingResult();
    calculateEnergyGWP ();
    updateEmbodiedCarbon();
    
    displayTotalResult();
    calculateYearlyGWP();
    project.building = [];
    project.building = building;
    console.log(building);
    console.log(project);
}

function updateEnergyInputs () {
    const lifecycleYearsInput = document.getElementById("lifecycle-years-input");

    const heatingEnergyInput = document.getElementById("heating-input");
    const heatingEnergyCoefficientInput = document.getElementById("heating-gwp-input");

    const electricityEnergyInput = document.getElementById("electricity-input");
    const electricityEnergyCoefficientInput = document.getElementById("electricity-gwp-input");
    console.log(project.energy.heatingCoefficient);
    console.log(project.energy.electricityCoefficient);
    console.log(electricityEnergyCoefficientInput);
    console.log(electricityEnergyCoefficientInput.value);
    console.log(project.energy.electricityCoefficient);
    
    lifecycleYearsInput.value = project.lifecycleYears;
    heatingEnergyInput.value = project.energy.heatingEnergy;
    electricityEnergyInput.value = project.energy.electricityEnergy;
    heatingEnergyCoefficientInput.value = project.energy.heatingCoefficient;
    electricityEnergyCoefficientInput.value = project.energy.electricityCoefficient;
}


function updateEnergyData () {
    const lifecycleYearsInput = document.getElementById("lifecycle-years-input");

    const heatingEnergyInput = document.getElementById("heating-input");
    const heatingEnergyCoefficientInput = document.getElementById("heating-gwp-input");

    const electricityEnergyInput = document.getElementById("electricity-input");
    const electricityEnergyCoefficientInput = document.getElementById("electricity-gwp-input");
    lifecycleYears = getNumInputValue(lifecycleYearsInput);
    heatingEnergy = getNumInputValue(heatingEnergyInput);
    electricityEnergy = getNumInputValue(electricityEnergyInput);
    heatingCoefficient =getNumInputValue( heatingEnergyCoefficientInput);
    electricityCoefficient = getNumInputValue(electricityEnergyCoefficientInput);

    energy = {
        heatingEnergy : heatingEnergy,
        electricityEnergy : electricityEnergy,
        heatingCoefficient : heatingCoefficient,
        electricityCoefficient : electricityCoefficient,

    }
    console.log(energy);
    project.energy = energy;
    project.lifecycleYears = lifecycleYears;
    calculateEnergyGWP ();
}

function calculateEnergyGWP () {
    console.log(lifecycleYears);
    console.log(electricityGWP);
    console.log(electricityEnergy);
    console.log(electricityCoefficient);
    electricityGWPpartial = electricityEnergy * electricityCoefficient;
    console.log(electricityGWPpartial);
    heatingGWPpartial = heatingEnergy * heatingCoefficient;
    electricityGWP = buildingArea * electricityGWPpartial * lifecycleYears;
    console.log(electricityGWP);
    
    heatingGWP = buildingArea * heatingGWPpartial * lifecycleYears;
    operationalResult = heatingGWP + electricityGWP;
    console.log(electricityGWP);
    console.log(heatingGWP);
    updateEnergyContainer();
    updateEmbodiedCarbon();
    console.log(buildingGWPresult);
    console.log(embodiedResult);
    displayTotalResult();
    calculateYearlyGWP();
}

function updateEmbodiedCarbon () {
    embodiedResult = buildingGWPresult.A1A2A3+buildingGWPresult.C3+buildingGWPresult.C4;
}

function updateTotalCarbon () {
    totalResult = embodiedResult + operationalResult;
    
}


function calculateYearlyGWP () {
    yearlyGWP = [];
    yearlyGWPemb = [];
    yearlyGWPope = [];
    var yearlyOPE = operationalResult / lifecycleYears;
    for (var i = 0; i<lifecycleYears; i++) {
        if (i === 0) {
            yearsTable[i] = currentYear;
            yearlyGWPope[i] = yearlyOPE;
            
        } else {
            yearsTable[i] = yearsTable[i-1] + 1;
            yearlyGWPope[i] = yearlyGWPope[i-1] + yearlyOPE;

        }
        yearlyGWPemb[i] = embodiedResult;
        yearlyGWP[i] = yearlyGWPemb[i] + yearlyGWPope[i];
        /* console.log("Yearly Operational Carbon" + yearsTable[i]);
        console.log(yearlyGWPope[i]);
        console.log("Yearly Embodied Carbon" + yearsTable[i]);
        console.log(yearlyGWPemb[i]); */
        
    }
    
}


function calculateComponentsGWP () {

}



//// DISPLAY FUNCTIONS
function displayMaterialInfo () {
    addMaterialButton.addEventListener("click", addMaterialToWall);
    clearMaterialButton.addEventListener("click", clearMaterial);


    const materialInfoDisplay = document.getElementById("material-info");
    const materialInfoDisplayName = document.getElementById("material-info-name");
    const materialInfoDisplayLongName = document.getElementById("material-info-longname");
    const materialInfoDisplayGWPA1A2A3 = document.getElementById("material-info-gwp");
    const materialInfoDisplayComment = document.getElementById("material-info-comment");
    const materialInfoDisplayEPDSource = document.getElementById("material-info-EPD-source");
    const materialInfoDisplayProducer  = document.getElementById("material-info-producer");

    materialInfoDisplayName.innerHTML = `<i class="fa-solid fa-tag"></i> ` + material[0].materialname;
    materialInfoDisplayLongName.innerHTML = `<i class="fa-solid fa-tags"></i> ` + material[0].materiallongname;
    materialInfoDisplayGWPA1A2A3.innerHTML = `<i class="fa-solid fa-smog"></i> GWP A1-A3: ` + roundGWP(material[0].materialgwp.A1A2A3) + 
    " kgCO2/" + material[0].materialunit + " C3: "+roundGWP(material[0].materialgwp.C3) + " kgCO2/" + material[0].materialunit + 
    " C4: "+roundGWP(material[0].materialgwp.C4) + " kgCO2/" + material[0].materialunit + 
    " D: "+roundGWP(material[0].materialgwp.D) + " kgCO2/" + material[0].materialunit;
    materialInfoDisplayComment.innerHTML = `<i class="fa-solid fa-book"></i> ` + material[0].materialcomment;
    materialInfoDisplayEPDSource.innerHTML = `<i class="fa-solid fa-up-right-from-square"></i> Source: <a id="material-info-EPD-link" class="text-primary" href="`+ material[0].materialepdlink + `" target="_blank" > `+ material[0].materialepdsource + "</a>" ;
    materialInfoDisplayProducer.innerHTML = `<i class="fa-solid fa-industry"></i> ` + material[0].materialproducer;
}

function clearComponent () {
    console.log("clear");
    wallTableDiv.innerHTML = "";
    wall = [];
    calculateGWP();
}

function updateResult () {
    componentResultDisplay.innerHTML = `<span class="tooltipspan">Result: <b>` + (Math.round((result.A1A2A3+result.C3+result.C4)*100)/100) + `</b> kgCO2/m2
                                        <span class="tooltiptext">Sum of A1-A3, C3 and C4 for this component.</span></span>`;
}
function updateBuildingResult () {
    BuildingResultDisplay.innerHTML = "Embodied Carbon: <b>" + (Math.round((buildingGWPresult.A1A2A3+buildingGWPresult.C3+buildingGWPresult.C4)*100)/100) + "</b> kgCO2";
    BuildingResultDisplayM2.innerHTML = "Ratio: <b>" + (Math.round((buildingGWPresult.A1A2A3+buildingGWPresult.C3+buildingGWPresult.C4)/ buildingArea*10) /10) + "</b> kgCO2/m2";
}

function clearMaterial () {
    
    
    material = [];
    const materialInfoDisplay = document.getElementById("material-info");
    const materialInfoDisplayName = document.getElementById("material-info-name");
    const materialInfoDisplayLongName = document.getElementById("material-info-longname");
    const materialInfoDisplayGWP = document.getElementById("material-info-gwp");
    const materialInfoDisplayComment = document.getElementById("material-info-comment");
    const materialInfoDisplayEPDSource = document.getElementById("material-info-EPD-source");
    const materialInfoDisplayProducer  = document.getElementById("material-info-producer");

    materialInfoDisplayName.innerHTML = `<i class="fa-solid fa-tag"></i> `;
    materialInfoDisplayLongName.innerHTML = `<i class="fa-solid fa-tags"></i> `;
    materialInfoDisplayGWP.innerHTML = `<i class="fa-solid fa-smog"></i> `;
    materialInfoDisplayComment.innerHTML = `<i class="fa-solid fa-book"></i> ` ;
    materialInfoDisplayEPDSource.innerHTML = `<i class="fa-solid fa-up-right-from-square"></i>`;
    materialInfoDisplayProducer.innerHTML = `<i class="fa-solid fa-industry"></i>`;
    
}






function displayTotalResult () {
    updateTotalCarbon ();

    const projectEmbodiedResultDisplay = document.getElementById("project-embodied-result");
    const projectEmbodiedRatioDisplay = document.getElementById("project-embodied-ratio-result");
    const projectOperationalResultDisplay = document.getElementById("project-operational-result");
    const projectOperationalRatioDisplay = document.getElementById("project-operational-ratio-result");
    const projectTotalResultDisplay = document.getElementById("project-carbon-result");
    const projectTotalRatioDisplay = document.getElementById("project-carbon-ratio-result");

    projectEmbodiedResultDisplay.innerHTML = rnd2(embodiedResult) + ` kgCO2`;
    projectEmbodiedRatioDisplay.innerHTML = perArea(embodiedResult) + ` kgCO2/m2`;
    projectOperationalResultDisplay.innerHTML = rnd2(operationalResult) + ` kgCO2`;
    projectOperationalRatioDisplay.innerHTML = perArea(operationalResult) + ` kgCO2/m2`;
    projectTotalResultDisplay.innerHTML = rnd2(totalResult) + ` kgCO2`;
    projectTotalRatioDisplay.innerHTML = perArea(totalResult) + ` kgCO2/m2`;


}

function updateEnergyContainer () {
    const partialHeatingResult = document.getElementById("partial-heating-result");
    const partialElectricityResult = document.getElementById("partial-electricity-result");
    const energyResult = document.getElementById("energyResultDisplay");
    const energyResultM2 = document.getElementById("energyResultDisplayM2");

    partialHeatingResult.innerHTML = `<b>` + rnd1(heatingGWPpartial) + `</b>`;
    partialElectricityResult.innerHTML = rnd1(electricityGWPpartial);
    console.log(operationalResult);
    console.log(buildingArea);
    console.log(lifecycleYears);
    console.log(rnd1(operationalResult/(buildingArea*lifecycleYears)));
    energyResult.innerHTML = `Operational Carbon: <b>` + rnd1(operationalResult) + `</b> <small>kgCO2</small>`;
    energyResultM2.innerHTML = `Ratio: <b>` + rnd1(operationalResult/(buildingArea*lifecycleYears)) + `</b> <small>kgCO2/m2*year</small>`;
}

function rnd2(number) {
    return Math.round(number*100)/100;
}
function rnd1(number) {
    return Math.round(number*10)/10;
}

function perArea (number) {
    return Math.round(number/buildingArea*10)/10;
}
//// COMPONENT FUNCTIONS

function addComponent () {
    componentName = document.getElementById("component-name-input").value;
    componentArea =  getNumInputValue(document.getElementById("component-area-input"));
    console.log(project);
    console.log(project.building);
    console.log("add");
    if (wall.length === 0) {
        console.log("component empty");
    } else {
        wallTableDiv.innerHTML = "";
        console.log(result);
        console.log(result.A1A2A3);
        project.building.push({
            componentid: building.length,
            componentname: componentName,
            componentamount: componentArea,
            componentgwp: {
                A1A2A3 : result.A1A2A3,
                C3: result.C3,
                C4 : result.C4,
                D : result.D
            },
            componentunit: "m2",
            component: wall

        });
        wall = [];
        console.log("Building structure");
        console.log(building);
        
        console.log(project);
        showBuilding();
        calculateGWP();
        componentNameId++;
        document.getElementById("component-name-input").value = "Wall#"+componentNameId;
        document.getElementById("component-area-input").value = 1;
        const BuildingNameInput = document.getElementById("component-name-input");
        
        BuildingNameInput.innerHTML = "Component#";
        calculateBuildingGWP();
    }
    
}


//// MATERIAL INFORMATION FUNCTIONS

function showMaterialInfo (that) {
    //console.log("that is");
    material = [];
    //console.log(that.data);
    material.push({
        materialname: that.data.name,
        materiallongname: that.data.longname,
        materialgwp: that.data.gwp,
        materialunit: that.data.unit,
        materialcomment: that.data.comment,
        materialepdsource: that.data.epdsource,
        materialepdlink: that.data.epdlink,
        materialproducer: that.data.producer,
        materialdefaultamount: that.data.defaultAmount,
        
    });
    console.log(material);
    displayMaterialInfo();
}

function addMaterialToWall () {
    if (material.length === 0) {
        console.log("empty");
    } else {
        wall.push({
            layerid: uuidv4(),
            layername: material[0].materialname,
            layerthickness: material[0].materialdefaultamount,
            layergwp: {
                A1A2A3 : material[0].materialgwp.A1A2A3,
                C3 : material[0].materialgwp.C3,
                C4 : material[0].materialgwp.C4,
                D : material[0].materialgwp.D,
            },
            layerunit: material[0].materialunit,
            layermaterial: material[0]
    });
    
    console.log(wall);
    showWall();
    }
    
}

function addToWall (that) {
    
    wall.push({
            layerid: wall.length,
            layername: that.data.name,
            layerthickness: that.data.defaultAmount,
            layergwp: that.data.gwp,
            layerunit: that.data.unit
    });
    
    
    showWall();
}





//// DATA VIZ
function updateDataViz () {
    
    updateChartLifecycleGWP();
    updateChartComponentsGWP ();
}

//import Chart from 'chart.js/auto';
function updateChartLifecycleGWP () {
    
    if (ChartLifecycleGWP === '') {

    } else {
        ChartLifecycleGWP.destroy();
    }
    console.log(yearsTable);
    console.log(yearlyGWP);
    ctxChartLifecycleGWP = document.getElementById('chart-area-lifecycleGWP');
    ChartLifecycleGWP = new Chart(ctxChartLifecycleGWP, {
                            type: 'line',
                            data: {
                                labels: yearsTable,
                                fontColor : '#222d43',
                                scales: {
                                    x: {
                                    ticks: {
                                        color: "#222d43"
                                    },
                                    },
                                    y: {
                                    ticks: {
                                        color: "#222d43",
                                        }
                                    },
                                    grid: {
                                        color: '#222d43'
                                    },
                                    },
                                
                                datasets:  [
                                    {
                                        label: 'Operational Carbon',
                                        data: yearlyGWPope,
                                        borderColor: '#30de80'
                                        
                                    },
                                    {
                                        label: 'Embodied Carbon',
                                        data: yearlyGWPemb,
                                        borderColor: '#ffa929'
                                    },
                                    {
                                        label: 'Whole Life Carbon',
                                        data: yearlyGWP,
                                        borderColor: '#222d43'
                                    }
                                    ]
                            },
                            options: {
                                showLines: false,
                                plugins: { 
                                    datalabels: {
                                        color: "#222d43",
                                    },
                                    legend: {
                                        labels: {
                                            color: "#222d43", 
                                            font: {
                                            size: 18
                                            }
                                        }
                                    }
                                },
                                responsive: true,
                            },
                });
                
}

function updateChartComponentsGWP () {
    
    if (ChartComponentsGWP === '') {

    } else {
        ChartComponentsGWP.destroy();
    }
    
    console.log(componentLabels);
    console.log(componentsGWPtable);
    console.log(componentsGWPtable.A1A2A3);
    console.log(componentsGWPtable.C3);
    ctxChartComponentsGWP = document.getElementById('chart-area-componentsGWP');
    ChartComponentsGWP = new Chart(ctxChartComponentsGWP, {
                            type: 'bar',
                            data: {
                                labels: componentLabels,
                                fontColor : '#222d43',
                                scales: {
                                    x: {
                                    ticks: {
                                        color: "#222d43"
                                    },
                                    },
                                    y: {
                                    ticks: {
                                        color: "#222d43",
                                        }
                                    },
                                    grid: {
                                        color: '#222d43'
                                    },
                                    },
                                
                                datasets:  [
                                    {
                                        label: 'A1-A3',
                                        data: componentsGWPtable.A1A2A3,
                                        backgroundColor: '#ff2452',
                                        stack: 'Stack 0'
                                        
                                    },
                                    {
                                        label: 'C3',
                                        data: componentsGWPtable.C3,
                                        backgroundColor: '#ffa929',
                                        stack: 'Stack 0'
                                        
                                    },
                                    {
                                        label: 'C4',
                                        data: componentsGWPtable.C4,
                                        backgroundColor: '#ffcf45',
                                        stack: 'Stack 0'
                                        
                                    },
                                    {
                                        label: 'D',
                                        data: componentsGWPtable.D,
                                        backgroundColor: '#30de80',
                                        stack: 'Stack 1'
                                        
                                    }
                                ],

                            },
                            options: {
                                showLines: false,
                                plugins: { 
                                    datalabels: {
                                        color: "#222d43",
                                    },
                                    legend: {
                                        labels: {
                                            color: "#222d43", 
                                            font: {
                                            size: 18
                                            }
                                        }
                                    }
                                },
                                responsive: true,
                                scales: {
                                    x: {
                                      stacked: true,
                                    },
                                    y: {
                                      stacked: true
                                    }
                                }
                        
                            },
                });


}

// borderColor: 'rgb(37, 130, 236)','rgb(122, 237, 14)', 'rgb(235, 41, 80)'],
                              

                    


function displayTopMessage (message = '') {
    const topMessageDisplay = document.getElementById("top-message-display");
    topMessageDisplay.innerHTML = message;
}


function toggleButtonVisibility () {
    const buttonLoginDisplay = document.getElementById("nav-login");
    const buttonSignupDisplay = document.getElementById("nav-signup");
    const buttonLogoutDisplay = document.getElementById("nav-logout");
    
    if (state === "logged") {
        
        buttonLoginDisplay.style.display='none';
        
        buttonSignupDisplay.style.display='none';
        
        buttonLogoutDisplay.style.display='block';
    } else if (state === "notlogged") {

        buttonLoginDisplay.style.display='block';
        
        buttonSignupDisplay.style.display='block';
        
        buttonLogoutDisplay.style.display='none';
    }
}









function checkState() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authFunc= urlParams.get('auth');
    console.log(authFunc);
    return authFunc;
}
function checkPage() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    console.log(page);
    return page;
}
function checkId() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    console.log(id);
    return id;
}



function navButtonsListeners () {
    if (document.querySelector("#nav-logout")) {
        document.querySelector("#nav-logout").addEventListener("click", () => {
            signOut();
        })
    }
    if (document.querySelector("#nav-login")) {
        document.querySelector("#nav-login").addEventListener("click", () => {
            window.location = '/?auth=login';   
        })
    }
    if (document.querySelector("#nav-signup")) {
        document.querySelector("#nav-signup").addEventListener("click", () => {
            window.location = '/?auth=signup'; 
        })
    }

}


function addToMaterialLibrary () {
    "add-material-add-btn"
}