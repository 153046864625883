console.log("auth_login.js loaded...");

import { Auth } from 'aws-amplify';

// Sign In function
export const signIn = async ({username, password}) => {
    try {
        const { user } = await Auth.signIn(username, password);
        console.log(user)
        alert("user signed in");
        window.location = '/?auth=calculator'
    } catch (error) {
        console.log('error signing in', error);
        alert(error.message);
        window.location = '/?auth=login'
    }
}

// Event Listeners if user is on Login page


;