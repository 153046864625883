

export function showBlog (data) {
    console.log(data);
}




export async function checkBlogListDatabase (fullApiPath, headers) {
    const apiBlogUrl = fullApiPath+"getblog";
    console.log(headers);
    const responseBlog = await fetch(apiBlogUrl, headers);
    console.log(responseBlog);
    const blogdata = await responseBlog.json();
    const bloglist = blogdata.Items;
    console.log(bloglist);
    return bloglist;
}

export function processBlogData (data) {
    
    //const blogTableDiv = document.getElementById("blogposts-table");
    data.forEach(function (el) {
        const articleid = el.articleid; // 1
        const name = el.name; // Article about carbon
        const promo = el.promo; // A short story about why this is important
        const promoimg = el.promoimg; // path to promoimg on server
        const content = el.content;
        const tags = el.tags;
        
  
    })
}

export function showBlogList(blogData) {

    console.log (blogData);
    const blogList = document.getElementById("blogposts");   
    if (blogData.length > 0) {       
        
        var fullList = '';
        
        
        

        var i = 0;
        blogData.forEach(function (el) {
            const name = el.name;
            const promo = el.promo;
            const image = el.promoimg;
            const articleid = el.articleid;
            const date = el.date;
            var blogPost = /*html*/`
            <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 my-5 align-items-center rounded-3 border shadow-lg">
                
                <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
                    <h1 id="blogpost-title" class="display-4 fw-bold lh-1">${name}</h1>
                    <p id="blogpost-lead" class="lead">${promo}</p>
                    </p>
                    <p class="text-muted">${date} </p>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                    <button id="blogpost-button" type="button" class="btn btn-primary fw-bold btn-lg" onclick="location.href='/?auth=blog&id=${articleid}';">Read more</button>
                    
                    </div>
                    
                </div>
                <div class="col-lg-6">
                    <img src="./assets/images/${image}" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
                </div>
            
            </div>
        
        `
            
            
            console.log (blogPost);
            
            i++;
            fullList += blogPost;
            console.log (fullList);
        }) 
    } else {
        
    }
    blogList.innerHTML = fullList;
    console.log (blogList);
}

export function showBlogPost(blogData, id) {

    console.log (blogData);
    const blogList = document.getElementById("blogposts");  
    
    

    const el = findArticle(blogData, id);
    if (!el) {
        blogList.innerHTML = `No article found with ID ${id}`;
    } 
    console.log(el);
    const name = el.name;
    const promo = el.promo;
    const image = el.promoimg;
    const content = el.content;
    const date = el.date;
    var blogPost = /*html*/`
            <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 my-5 align-items-center rounded-3 border shadow-lg">
                
                <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
                    <h1 id="blogpost-title" class="display-4 fw-bold lh-1">${name}</h1>
                    <p id="blogpost-lead" class="lead">${promo}</p>
                    </p>
                    <p class="text-muted">${date} </p>
                </div>
                <div class="col-lg-6">
                    <img src="./assets/images/${image}" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
                </div>
                <div class="col-lg-12 p-3 p-lg-5 pt-lg-3">
                    
                    <p id="blogpost-content">${content}</p>
                    </p>
                    
                </div>
            
            </div>
        
    `
            
            
    console.log (blogPost);
            
    blogList.innerHTML = blogPost;
    
    
}

function findArticle(blogList, id) {
    const iid = parseInt(id);
    console.log(id);
    for (let i = 0; i < blogList.length; i++) {
        const articleid = parseInt(blogList[i].articleid);
        console.log(articleid);
        if (articleid == iid) {
            return blogList[i];
        }
    }
    return null; // if no match is found
  }
