export const loginHTML = /*html*/ `

<div id="container-auth-login" class="container-box" >
            <section id="auth-login" class="formwrapper"> 
                <div class="d-flex justify-content-center formcontent">
                    <div class="align-self-center">
                        <h2>Login</h2>
                        <form id="form-auth-login">
                            <div class="mb-3">
                                <label for="formLoginEmail" class="form-label">Email address</label>
                                <input type="email" class="form-control" id="formLoginEmail" aria-describedby="emailHelp">                        
                            </div>
                            <div class="mb-3">
                                <label for="formLoginPassword" class="form-label">Password</label>
                                <input type="password" class="form-control" id="formLoginPassword">
                            </div>                    
                            <button id="btnLogin" type="submit" class="btn btn-primary">LOG IN</button>                    
                        </form>
                        <p class="mt-3 mb-0">
                            <small>
                                Don't have an account?
                                <a class="text-decoration-none" href="./?auth=signup">Sign up</a>
                            </small>
                        </p>
                        <p class="mt-0">
                            <small>
                                Forgot password?
                                <a class="text-decoration-none" href="./?auth=forgot">Reset password</a>
                            </small>
                        </p>
                    </div>
                </div> 
            </section>
        </div>

`

export const resetHTML = /*html*/`
<div id="container-auth-forgot" class="container-box" >
            <section id="auth-forgot-password" class="formwrapper">
                <div class="d-flex justify-content-center formcontent">
                    <div class="align-self-center">
                        <h2>Reset password</h2>
                        <form id="form-auth-forgot-password">
                            <div class="mb-3">
                                <label for="formForgotEmail" class="form-label">Email address</label>
                                <input type="email" class="form-control" id="formForgotEmail" aria-describedby="emailHelp">                        
                            </div>                            
                            <button id="btnForgot" type="submit" class="btn btn-primary">Reset</button>
                        </form>
                    </div>
                </div>
            </section>

        </div>

`




export const confirmpassHTML = /*html*/`
<div id="container-auth-confirm-forgot" class="container-box" >
    <section id="auth-forgot-password-confirm" class="formwrapper">
        <div class="d-flex justify-content-center formcontent">
            <div class="align-self-center">
                <h2>Confirm New Password</h2>
                <form id="form-auth-forgot-password-confirm">
                    <div class="mb-3">
                        <label for="formForgotConfirmEmail" class="form-label">Email address</label>
                        <input type="email" class="form-control" id="formForgotConfirmEmail" aria-describedby="emailHelp" value="" readonly> 
                    </div>
                    <div class="mb-3">
                        <label for="formForgotConfirmCode" class="form-label">Confirmation Code (via email)</label>
                        <input type="text" class="form-control" id="formForgotConfirmCode">
                    </div>
                    <div class="mb-3">
                    <label for="formForgotConfirmPassword" class="form-label">New Password</label>
                    <input type="password" class="form-control" id="formForgotConfirmPassword">
                </div>             
                    <button id="btnConfirmForgot" type="submit" class="btn btn-primary">Confirm</button>                  
                </form>
            </div>
        </div>
    </section>
</div>`
export const confirmHTML = /*html*/`
<div id="container-auth-signup-confirm" class="container-box" >
            <section id="auth-signup-confirm" class="formwrapper">
                <div class="d-flex justify-content-center formcontent">
                    <div class="align-self-center">
                        <h2>Confirm Email Address</h2>
                        <form id="form-auth-signup-confirm">
                            <div class="mb-3">
                                <label for="formSignUpConfirmEmail" class="form-label">Email address</label>
                                <input type="email" class="form-control" id="formSignUpConfirmEmail" aria-describedby="emailHelp" value="" > 
                            </div>
                            <div class="mb-3">
                                <label for="formSignUpConfirmCode" class="form-label">Confirmation Code</label>
                                <input type="text" class="form-control" id="formSignUpConfirmCode">
                            </div>                    
                            <button id="btnConfirm" type="submit" class="btn btn-primary">Confirm</button>                  
                        </form>
                        <p class="mt-3">
                        <small>
                            Didn't get your code? 
                            <a id="btnResend" class="text-decoration-none" href="#">Resend</a>
                        </small>
                        </p>
                        <p class="mt-3">
                        <small>
                            <i>If you still have problems with receiving code, check Your spam box, or use a different email address.</i>
                        </small>
                        </p>
                    </div>
                </div>
            </section>

        </div>

`

export const signupHTML = /*html*/`
<div id="container-auth-signup" class="container-box" >
            <section id="auth-signup" class="formwrapper">   
                <div class="d-flex justify-content-center formcontent">
                    <div class="align-self-center">
                        <h2>Sign up</h2>
                        <form id="form-auth-signup">
                            <div class="mb-3">
                                <label for="formSignUpEmail" class="form-label">Email address</label>
                                <input type="email" class="form-control" id="formSignUpEmail" aria-describedby="emailHelp" required>                        
                            </div>
                            <div class="mb-3">
                                <label for="formSignUpPassword" class="form-label">Password</label>
                                <input type="password" class="form-control" id="formSignUpPassword" minlength="6" required>
                            </div> 
                            <div class="mb-3">
                                <input class="form-check-input" type="checkbox" value="" id="formRules" required>
                                <label class="form-check-label" for="rules">
                                    I agree to the <a target="_blank" href="/?auth=rules">Terms of Service</a>
                                </label>
                            </div>  
                            <div class="mb-3">
                                By creating an account, you acknowledge the <a target="_blank" href="/?auth=rules">Privacy Policy</a>, and agree to receive emails about SLAD.AI and related services
                            </div>                
                            <button id="btnSignUp" type="submit" class="btn btn-primary">Sign up</button>
                        </form>
                        <p class="mt-3">
                            <small>
                                Already have an account?
                                <a class="text-decoration-none" href="./?auth=login">Sign in</a>
                            </small>
                        </p>
                        <p class="mt-3">
                            <small>
                                Already received a confirmation code?
                                <a class="text-decoration-none" href="./?auth=confirm">Confirm!</a>
                            </small>
                        </p>
                    </div>
                </div>
            </section>

        </div>


`


export const promoHTML = /*html*/`
<div id="promo-container" class="container my-5">
    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        
    <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
        <h1 class="display-4 fw-bold lh-1">SLAD.AI <br>Easy Carbon Footprint Calculator</h1>
        <p class="lead">Calculate the carbon footprint of your architectural project in few minutes!
            SLAD.AI calculates whole life carbon (embodied production A1-A3, end of life C3-C4 and operational carbon B6 lifecycle phases) of your building according to the european norm EN-15978.</p>
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
        <button type="button" class="btn btn-primary fw-bold btn-lg" onclick="location.href='/?auth=signup';">Create an account for free!</button>
        <button type="button" class="btn btn-outline-dark btn-lg" onclick="location.href='/?auth=login';">I have an account</button>
        </div>
    </div>
    <div class="col-lg-6">
        <img src="./assets/images/slad5.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
    </div>
    
    </div>
</div>

<div id="promo-container" class="container my-5">
    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        <div class="col-lg-6">
            <img src="./assets/images/slad1.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
        </div>
    <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
        <h1 class="display-4 fw-bold lh-1">Optimize Your building project with the power of Artificial Intelligence</h1>
        <p class="lead">Ask SLAD.AI bot (powered by Openai CHATGPT) for suggestions about your material choices. SLAD.AI will suggest you better possibilities. </p>
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
        <button type="button" class="btn btn-primary btn-lg px-4 me-md-2 fw-bold" onclick="location.href='/?auth=about';">About</button>
        <button type="button" class="btn btn-outline-dark btn-lg px-4" onclick="location.href='/?auth=about';">FAQ</button>
        </div>
    </div>
    
</div>

<div id="promo-container" class="container my-5">
    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        
    <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
        <h1 class="display-4 fw-bold lh-1">Calculate on the cloud</h1>
        <p class="lead">SLAD.AI calculates the carbon footprint on the cloud, allowing You to work on desktop, mobile or tablet. </p>
        
        <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
        <button type="button" class="btn btn-primary fw-bold btn-lg" onclick="location.href='/?auth=calculate';">Start calculating!</button>
        <button type="button" class="btn btn-outline-dark btn-lg" onclick="location.href='/?auth=signup';">Create an account</button>
        </div>
    </div>
    <div class="col-lg-6">
        <img src="./assets/images/slad4.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
    </div>
    
    </div>
</div>

    
</div>`;



export const calculatorHTML = /*html*/`
<div id="auth-container" class="container my-2">

    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingEight">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                Project 
                </button>
            </h1>
            <div id="collapseEight" class="accordion-collapse collapse show" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                <div id="project-msg" class="">A new project has been created. Remember to click "Save project" before You close the window.</div>
                <div id="projectdata" class=""></div>
                <button id="new-project-btn" type="button" class="btn btn-primary text-white my-1" style="display: none;"><i class="fa-solid fa-folder-plus"></i> New project</button>
                <button id="load-project-btn" type="button" class="btn btn-info text-white my-1"><i class="fa-solid fa-up-right-from-square" style="display: none;"></i> Load project</button>
                <button id="remove-project-btn" type="button" class="btn btn-danger text-white my-1"><i class="fa-solid fa-trash-can" style="display: none;"></i> Remove project</button>
                <button id="save-project-btn" type="button" class="btn btn-primary text-white my-1"><i class="fa-solid fa-folder-plus"></i> Save project</button>
                <div class="energy-table-row">
                    <div class="row fw-bold mx-1">Project name:</div>
                    
                        <div class="row">
                            <div class="col-9 col-sm-6"><input id="project-name-input" class="form-control form-control-sm form-inline" type="text" value = "Project#01"></div>
                        </div>
                        <div class="row fw-bold mx-1">Author:</div>
                        <div class="row">
                            <div class="col-9 col-sm-6"><input id="author-input" class="form-control form-control-sm form-inline" type="text" value = "Architecture Studio"></div>
                        </div>
                        <div class="row fw-bold mx-1">Address:</div>

                        
                        <div class="row">
                            <div class="col-9 col-sm-6"><input id="address-input" class="form-control form-control-sm form-inline" type="text" value = "Street 5 Warsaw Poland"></div>
                        </div>
                </div> 
                
                
                </div>
            </div>
        </div>
    </div>

    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingTwelve">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                Add a new material (EPD)
                </button>
            </h1>
            <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                
                <button id="add-material-clear-btn" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-trash-can"></i> Clear</button>
                <button id="add-material-add-btn" type="button" class="btn btn-primary text-white"><i class="fa-solid fa-folder-plus"></i> Add to library</button>
                  
                <div id="material-info" class="material-info">
                    <div class="material-info-container">
                    <div class="col-6 col-sm-6">
                            <div>
                            <input class="form-check-input" type="checkbox" value="" id="privateCheck" checked>
                                <label class="form-check-label" for="privateCheck">
                                    Check if the material should be private (default)
                                </label>
                            </div>
                            <div class="">
                            <div class="material-info-row"><input id="add-material-info-name" class="material-info-row" placeholder="Short name"> <i class="fa-solid fa-tag"></i> Name </div>
                            <div class="material-info-row"><input id="add-material-info-longname" class="material-info-row" placeholder="Long name"> <i class="fa-solid fa-tags"></i> Longer Name </div>
                            <div class="material-info-row"><input id="add-material-info-unit" class="material-info-row" placeholder="m3"> <i class="fa-solid fa-smog"></i> Unit </div>
                            </div>
                            <div>
                            <div class="material-info-row"><input id="add-material-info-defamount" class="material-info-row" placeholder="1"> <i class="fa-solid fa-bars"></i> Default amount </div>
                            <div class="material-info-row"><input id="add-material-info-density" class="material-info-row" placeholder="1000"> <i class="fa-solid fa-weight-hanging"></i> Density [kg per m3]</div>
                            <div class="material-info-row"><input id="add-material-info-tags" class="material-info-row" placeholder="tag1 tag2..."> <i class="fa-solid fa-hashtag"></i> Tags (for searching) </div>
                            </div>
                            <div class="">
                            <div class="material-info-row"><input id="add-material-info-comment" class="material-info-row" placeholder="Comment..."> <i class="fa-solid fa-book"></i> Add personal notes...</div>
                            <div class="material-info-row"><input id="add-material-info-EPD-source" class="material-info-row" placeholder="Link to the source..."> <i class="fa-solid fa-up-right-from-square"></i> Link to the EPD</div>
                            <div class="material-info-row"><input id="add-material-info-EPD-link" class="material-info-row" placeholder="Name of the source..."> <i class="fa-solid fa-up-right-from-square"></i> Name of the EPD source</div>
                            <div class="material-info-row"><input id="add-material-info-producer" class="material-info-row" placeholder="Generic / Specific / Producer ..."> <i class="fa-solid fa-industry"></i> Data type</div>    
                            <div class="material-info-row"><input id="add-material-info-validityyear" class="material-info-row" placeholder="2029"> <i class="fa-solid fa-industry"></i> Validity year</div>    
                            </div>
                </div>
                <div class="col-6 col-sm-6">
                            <div class="">
                            <div class="material-info-row"><input id="add-material-info-gwpa1a3" class="material-info-row" placeholder="GWP: A1A3"> <i class="fa-solid fa-smog"></i> GWP A1-A3</div>
                            <div class="material-info-row"><input id="add-material-info-gwpa4" class="material-info-row" placeholder="GWP: A4"> <i class="fa-solid fa-smog"></i> GWP A4</div>
                            <div class="material-info-row"><input id="add-material-info-gwpa5" class="material-info-row" placeholder="GWP: A5"> <i class="fa-solid fa-smog"></i> GWP A5</div>
                            <div class="material-info-row"><input id="add-material-info-gwpb1" class="material-info-row" placeholder="GWP: B1"> <i class="fa-solid fa-smog"></i> GWP B1</div>
                            <div class="material-info-row"><input id="add-material-info-gwpc1" class="material-info-row" placeholder="GWP: C1"> <i class="fa-solid fa-smog"></i> GWP C1</div>
                            <div class="material-info-row"><input id="add-material-info-gwpc2" class="material-info-row" placeholder="GWP: C2"> <i class="fa-solid fa-smog"></i> GWP C2</div>
                            <div class="material-info-row"><input id="add-material-info-gwpc3" class="material-info-row" placeholder="GWP: C3"> <i class="fa-solid fa-smog"></i> GWP C3</div>
                            <div class="material-info-row"><input id="add-material-info-gwpc4" class="material-info-row" placeholder="GWP: C4"> <i class="fa-solid fa-smog"></i> GWP C4</div>
                            <div class="material-info-row"><input id="add-material-info-gwpd" class="material-info-row" placeholder="GWP: D"> <i class="fa-solid fa-smog"></i> GWP D</div>
                            
                            </div>
                            
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="accordion rounded-3 border shadow-lg my-2" id="user-material-library">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingThirteen">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                User Material library
                </button>
            </h1>

            
            <div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <input class="form-control" id="userMaterialFilter" type="text" placeholder="Search for an user-defined material..">
                    <div id="materials" >
                        <div class="container">
                            <div class="row header-table-row">
                                <div class="col-12 col-sm-6">
                                    <div class="row ">
                                        <div class="col-4 "> Name </div>
                                        <div class="col-4  text-end"> <span class="tooltipspan"> A1-A3 <span class="tooltiptext">Production phase - describing the CO2eq emissions due to the materials prodution</span></span></div>
                                        <div class="col-4 text-end"> <span class="tooltipspan"> C3  <span class="tooltiptext">Waste processing phase - for reuse, recovery and/or recycling</span></span></div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="row">
                                        
                                        <div class="col-4 text-end">  <span class="tooltipspan"> C4 <span class="tooltiptext">Waste disposal phase </span></span></div>
                                        <div class="col-4 text-end"> <span class="tooltipspan"> D <span class="tooltiptext">Benefits beyond the life cycle phase (optional)</span></span></div>
                                        <div class="col-4"> <span class="tooltipspan"><small>kgCO2/unit</small> <span class="tooltiptext">Total GWP of an element, excluding D</span></span></div>
                                        
                                    </div> 
                                </div> 
                    </div>    
                </div> 
                    </div>
                 
                    <div id="user-materials-table" style="height: 8rem; overflow-y: scroll; overflow-x: hidden;" class=" resizable-content">
                                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion rounded-3 border shadow-lg my-2" id="material-library">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingTwo">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                Material library
                </button>
            </h1>

            
            <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <input class="form-control" id="materialFilter" type="text" placeholder="Search for a material..">
                    <div id="materials" >
                        <div class="container">
                            <div class="row header-table-row">
                                <div class="col-12 col-sm-6">
                                    <div class="row ">
                                        <div class="col-4 "> Name </div>
                                        <div class="col-4  text-end"> <span class="tooltipspan"> A1-A3 <span class="tooltiptext">Production phase - describing the CO2eq emissions due to the materials prodution</span></span></div>
                                        <div class="col-4 text-end"> <span class="tooltipspan"> C3  <span class="tooltiptext">Waste processing phase - for reuse, recovery and/or recycling</span></span></div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="row">
                                        
                                        <div class="col-4 text-end">  <span class="tooltipspan"> C4 <span class="tooltiptext">Waste disposal phase </span></span></div>
                                        <div class="col-4 text-end"> <span class="tooltipspan"> D <span class="tooltiptext">Benefits beyond the life cycle phase (optional)</span></span></div>
                                        <div class="col-4"> <span class="tooltipspan"><small>kgCO2/unit</small> <span class="tooltiptext">Total GWP of an element, excluding D</span></span></div>
                                        
                                    </div> 
                                </div> 
                    </div>    
                </div> 
                    </div>
                 
                    <div id="materials-table" style="height: 8rem; overflow-y: scroll; overflow-x: hidden;" class=" resizable-content">
                                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingThree">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                Material info
                </button>
            </h1>
            <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                
                <button id="material-clear-btn" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-trash-can"></i> Clear</button>
                <button id="material-add-btn" type="button" class="btn btn-primary text-white"><i class="fa-solid fa-folder-plus"></i> Add to component</button>
                  
                <div id="material-info" class="material-info">
                    <div class="material-info-container">
                            <div class="">
                                <div id="material-info-name" class="material-info-row"><i class="fa-solid fa-tag"></i></div>
                                <div id="material-info-longname" class="material-info-row"><i class="fa-solid fa-tags"></i></div>
                                <div id="material-info-gwp" class="material-info-row"><i class="fa-solid fa-smog"></i></div>
                            </div>
                            <div class="">
                                <div id="material-info-comment" class="material-info-row"><i class="fa-solid fa-book"></i></div>
                                <div id="material-info-EPD-source" class="material-info-row"><i class="fa-solid fa-up-right-from-square"></i></div>
                                <div id="material-info-producer" class="material-info-row"><i class="fa-solid fa-industry"></i></div>    
                            </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>


    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingFour">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                Component 
                </button>
            </h1>
            <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="my-1">
                    Component name: 
                    <input id="component-name-input" class="form-control form-control-sm form-inline" type="text" value = "Wall#1">
                    </div>
                    <button id="component-clear-btn" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-trash-can"></i> Clear</button>
                    <button id="component-add-btn" type="button" class="btn btn-primary text-white"><i class="fa-solid fa-folder-plus"></i> Add to building</button>
                    <div class="my-1">
                        Component area [m2]: 
                        <input id="component-area-input" class="form-control form-control-sm form-inline" type="text" value = "1">
                    </div>
                    <div id="componentResultDisplay" class="alert alert-dark my-1">
                        Result: <b>0</b> kgCO2/m2 
                    </div>
                    <div class="header-table-row">
                        <div class="row">
                            <div class="col-4 col-sm-2">  Name </div>
                            <div class="col-4 col-sm-2"> <span class="tooltipspan"><span class="tooltiptext">Enter the amount of this material layer per unit of component</span>Amount</span></div>
                            <div class="col-4 col-sm-2 text-end"> Unit </div>
                            <div class="col-4 col-sm-2"> GWP/Unit </div>
                            <div class="col-4 col-sm-2 text-end" > <span class="tooltipspan"><span class="tooltiptext">Total embodied carbon for this component layer: A1-A3, C3, C4</span>GWP</span></div>
                            <div class="col-4 col-sm-2 text-end"> </div>
                        </div>
                    </div>
                    <div id="wall-table">
                        <div class="row">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingFive">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                Building 
                </button>
            </h1>
            <div id="collapseFive" class="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="my-1">
                    
                    Area [m2]: <input id="buildingAreaInput" class="form-control form-control-sm form-inline" type="number" value = "1000">
                    </div>
                    
                
                    <button id="calculate-button" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-rotate"></i> Recalculate</button>
                    
                    <div id="buildingResultDisplay" class="alert alert-dark my-1">
                        Embodied Carbon: <b>0</b> kgCO2  
                    </div>
                    <div id="buildingResultDisplayM2" class="alert alert-dark my-1">
                        Ratio: <b>0</b> kgCO2/m2
                    </div>
                    <div id="building-table">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
            
            
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingSix">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                Energy 
                </button>
            </h1>
            <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    
                    
                    <button id="calculate-energy-button" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-rotate"></i> Recalculate</button>
                    <div class="energy-table-row">
                        <div class="row fw-bold mx-1">Lifecycle years:</div>
                        <div class="row">
                        
                            <div class="col"><input id="lifecycle-years-input" class="form-control form-control-sm form-inline" type="text" value = "50"></div>
                        </div>
                    </div> 
                    <div class="energy-table-row">
                        <div class="row fw-bold mx-1">Heating:</div>
                        <div class="row">
                            <div class="col-6 col-sm"><input id="heating-input" class="form-control form-control-sm form-inline" type="text" value = "15"></div>
                            <div class="col-6 col-sm"> kWh/m2 </div>
                            <div class="col-6 col-sm"> <input id="heating-gwp-input" class="form-control form-control-sm form-inline" type="text" value = "0.349"></div> 
                            <div class="col-6 col-sm"><small>kgCO2/kWh</small></div>
                            <div class="col-11 col-sm results-rable-row-box" >
                                <span class="d-inline" id="partial-heating-result"> <b>0</b></span>
                                <span class="d-inline" > <small> kgCO2/m2</small></span>
                            </div>
                        </div>
                    </div> 
                    
                    <div class="energy-table-row">
                        <div class="row fw-bold mx-1">Electricity:</div>
                        <div class="row">
                            <div class="col-6 col-sm"><input id="electricity-input" class="form-control form-control-sm form-inline" type="text" value = "10"></div>
                            <div class="col-6 col-sm"> kWh/m2 </div>
                            <div class="col-6 col-sm"> <input id="electricity-gwp-input" class="form-control form-control-sm form-inline" type="text" value = "0.778"></div> 
                            <div class="col-6 col-sm"><small>kgCO2/kWh</small></div>
                            <div class="col-11 col-sm results-rable-row-box">
                                <span id="partial-electricity-result" class="d-inline"> <b>0</b></span>
                                <span class="d-inline"> <small>kgCO2/m2</small></span>
                            </div>
                            
                        </div>
                    </div>
                    <div id="energyResultDisplay" class="alert alert-dark my-1">
                        Operational Carbon: <b>0</b> kgCO2  
                    </div>
                    <div id="energyResultDisplayM2" class="alert alert-dark my-1">
                        Ratio: <b>0</b> kgCO2/m2*year  
                    </div>
                </div>
            </div>
        </div>
    </div>     
            
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingSeven">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                Results 
                </button>
            </h1>
            <div id="collapseSeven" class="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    
                <b>Embodied Carbon:</b> 
                    <div id="project-embodied-result" class="alert alert-dark my-1">
                        <b>0</b> kgCO2  
                    </div>
                    <div id="project-embodied-ratio-result" class="alert alert-dark my-1">
                        <b>0</b> kgCO2/m2*year  
                    </div>
                

                <b>Operational Carbon:</b>   
                    <div id="project-operational-result" class="alert alert-dark my-1">
                        <b>0</b> kgCO2  
                    </div>
                    <div id="project-operational-ratio-result" class="alert alert-dark my-1">
                        <b>0</b> kgCO2/m2*year  
                    </div>
                
                <b>Whole Life Carbon:</b>   
                    <div id="project-carbon-result" class="alert alert-dark my-1">
                        <b>0</b> kgCO2  
                    </div>
                    <div id="project-carbon-ratio-result" class="alert alert-dark my-1">
                        <b>0</b> kgCO2/m2*year  
                    </div>
                </div>
            </div>
        </div>
    </div>          

            
              
    
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingNine">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                Data visualization 
                </button>
            </h1>
            <div id="collapseNine" class="accordion-collapse collapse show" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                
                    <button id="data-button" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-rotate"></i> Recalculate</button>
                    <div class="energy-table-row">
                        <b>Whole Life Carbon</b> <small>embodied vs. operational</small>
                    </div>
                    <div class="energy-table-row">
                        <canvas id="chart-area-lifecycleGWP" class="chart"></canvas>
                    </div>
                    <div class="energy-table-row">
                        <b>Embodied Carbon</b> <small>by component</small>
                    </div>
                    <div class="energy-table-row">
                        <canvas id="chart-area-componentsGWP" class="chart"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>        
      
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingTen">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                Export data 
                </button>
            </h1>
            <div id="collapseTen" class="accordion-collapse collapse show" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                   
                    <button id="excel-button" type="button" class="btn btn-danger text-white"><i class="fa-solid fa-file-excel"></i> Export EXCEL</button>
                    
                    
                </div>
            </div>
        </div>
    </div>  
    
    <div class="accordion rounded-3 border shadow-lg my-2" >
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingEleven">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
            Ask SLAD.AI 
            </button>
            </h1>
            
            <div id="collapseEleven" class="accordion-collapse collapse show" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                <div class="my-1">
                Ask SLAD.AI bot (powered by CHAT GPT OpenAI), to suggest changes in your material choices. Artificial Intelligence will analyze your project and provide you with a list of possible changes. 
           
                </div> 
                <div class="my-1">
                Generating answer might take up to 60 seconds. Do not refresh the page after clicking the button. The answer is not guaranteed to be accurate. The results may vary...
                </div>   
                    <button id="ai-button" type="button" class="btn btn-info text-white"><i class="fa-solid fa-robot"></i> Ask SLAD.AI</button>
                    <div id="slad-ai-prompt" class="alert alert-dark my-1">
                        Prompt: ...
                    </div>
                    <div id="slad-ai-response" class="alert alert-dark my-1">
                        Answer: ...
                    </div>
                </div>
            </div>
        </div>
    </div> 
   
`


export const aboutHTML = /*html*/`
<div id="about-container" class="container my-2">
	<div class="accordion rounded-3 border shadow-lg my-2" id="instructions">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                About
                </button>
            </h1>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <img src="./assets/images/slad2.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
                        </div>
                        <div class="col-lg-6">
                            <div class="my-1">
                                <strong>SLAD</strong>, pl. <i>"ślad"</i>, means footprint in polish. This app helps You to calculate carbon footprint (pl. <i>"ślad węglowy"</i>) of your architectural projects with ease. 
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion rounded-3 border shadow-lg my-2" id="instructions">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Instructions
                </button>
            </h1>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <img src="./assets/images/slad6.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
                        </div>
                        <div class="col-lg-6">
                            <div class="my-1">
                                <strong>SLAD.AI</strong> calculates whole life carbon (embodied production A1-A3, end of life C3-C4 and operational carbon B6 lifecycle phases) of your building according to the european norm EN-15978. The calculation is divided into a few steps:
                            </div>
                            <ul class="list-group">
                                            <li class="list-group-item">Select material from the Material Library</li>
                                            <li class="list-group-item">Click on it to add to the Building Component layers</li>
                                            <li class="list-group-item">Modify thickness and click OK</li>
                                            <li class="list-group-item">Click ADD to add the Component to the Building</li>
                                            <li class="list-group-item">Modify the Building Area and component amounts</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion rounded-3 border shadow-lg my-2" id="instructions">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Frequently Asked Questions
                </button>
            </h1>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                <div class="my-1">
                    Answers to the most common questions:
                </div>
                <ul class="list-group my-2">
                                <li class="list-group-item"><strong>Can I add my own materials to the library?</strong></li>
                                <li class="list-group-item">This feature will come in future release.</li>
                </ul>
                
                </div>
            </div>
        </div>
    </div>
    <div class="accordion rounded-3 border shadow-lg my-2" id="instructions">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Contact
                </button>
            </h1>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                <div class="my-1">
                    If You are looking for experts in calculating carbon footprint of Your building, project or concept - please contacts us at <a href="mailto:contact@slad.ai">contact@slad.ai</a>. We can help You selecting a consultant that will estimate carbon footprint for You!
                </div>
                
                </div>
            </div>
        </div>
    </div>



</div>`


export const rulesHTML = /*html*/`
<div id="about-container" class="container my-2">

    <div class="accordion rounded-3 border shadow-lg my-2" id="instructions">
        <div class="accordion-item">
            <h1 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Privacy Policy, Terms and Conditions
                </button>
            </h1>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                <div class="my-1">
                    <p>
                        <h1>Terms of Service</h1>
                    </p>
                    <p>
                        <span><h3>General rules</h3></span>
                    </p>
                    <ol>
                    <li >
                        <span>The Regulations define the rules of using the www.slad.ai website (hereinafter: the Website).</span>
                    </li>
                    <li>
                    <span>The website is in the BETA version, which means possible instability of the system and the possibility of data loss. The website does not guarantee error-free operation of the application.</span></li><li class="c10 li-bullet-0"><span class="c4">The software is provided &quot;as is&quot;, with all its faults, and without any further warranty. Service hereby declares that it is not responsible for software warranties and conditions that establish liability under express, implied or statutory guarantees of quality or warranty, including but not limited to any implied warranties of merchantability, satisfactory quality, fitness for a particular purpose and accuracy.</span></li><li class="c10 li-bullet-0"><span class="c4">By registering on the Website, the User accepts the terms of these Regulations and the Privacy Policy.</span></li><li class="c10 li-bullet-0"><span class="c4">By using the website, the user agrees to the collection of anonymized data about the implemented projects, the performance of statistical analyzes on the data, and the training of machine learning models.</span></li><li class="c10 li-bullet-0"><span class="c4">The Terms of Service can be changed at any time by the administrator. The user should read the current version of the Terms of Service each time they use the Website.</span></li></ol>
                    
                    <p>
                        <span><h1>Privacy Policy</h1></span>
                    </p>
                    <p>
                        <h3>General provisions</h3>
                    </p>

                    <ol><li><span>The privacy policy defines how the Users&#39; personal data necessary to provide electronic services via the website is collected, processed and stored</span><span class="c7">www.slad.ai</span><span class="c4">&nbsp;(hereinafter: the Website).</span></li><li class="c2 li-bullet-0"><span class="c4">The website collects only personal data necessary to provide and develop the services offered on it.</span></li>
                    <li><span>Personal data collected via the Website are processed in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/ 46 / EC (General Data Protection Regulation, hereinafter referred to as GDPR) and the Personal Data Protection Act of May 10, 2018.</span></li></ol>
                    <p><span><h3>Data administrator</h3></span></p>
                    <p><span>The administrator of personal data collected through the Website is </span>
                    <span>Mateusz Płoszaj-Mazurek</span>, 
                    <span><a href="https://www.slad.ai">www.slad.ai</a></span><span>, e-mail address: </span>
                    <span><a href="mailto:contact@slad.ai">contact@slad.ai</a></span><span>&nbsp;(hereinafter: Administrator).</span></p>
                    
                    
                    <p>
                    <span><h3>Purpose of collecting personal data</h3></span></p>
                    
                    <ol><li>
                    <span>Personal data is used for the purpose of:</span></li></ol>
                    
                    <ul>
                    <li><span>account registration and verification of the User&#39;s identity,</span></li>
                    <li><span>enable logging into the Website,</span></li>
                    <li><span>performance of the contract for services and e-services,</span></li><li><span>communication with the User (livechat, contact form, etc.)</span></li>
                    <li><span>sending the newsletter (after the User agrees to receive it),</span></li><li><span>running a comment system,</span></li><li>
                    <span>providing social services,</span></li><li><span>promotion of the Administrator&#39;s offer,</span></li>
                    <li><span>marketing, remarketing, affiliation,</span></li><li><span>personalization of the Website for Users,</span></li>
                    <li><span>analytical and statistical activities,</span></li><li><span>debt collection,</span></li>
                    <li><span>establishing and pursuing claims or defending against them.</span></li></ul><ol>
                    </ol>
                    <ol start="2">
                    <li><span>Providing data is voluntary, but necessary to conclude a contract or use other functionalities of the Website.</span></li></ol>
                    
                    <p><span><h3>Type of personal data processed</h3></span></p>
                    <p><span>The Administrator may process the User&#39;s personal data: name and surname, e-mail address.</span></p>
                    <p><span><h3>The period of personal data processing</h3></span></p><p><span>Users&#39; personal data will be processed for the period of:</span></p>
                    <ul><li><span>when the basis for data processing is the performance of the contract - until the statute of limitations for claims after its performance,</span></li>
                    <li><span>when the basis for data processing is consent - until the consent is revoked, and after the consent is revoked, until the limitation period for claims.</span></li></ul>
                    <p><span>In both cases, the limitation period is 6 years, and for claims for periodic benefits and claims related to running a business - 3 years (unless a special provision provides otherwise).</span></p><p>
                    <span><h3>Sharing personal data</h3></span></p><ol>
                    <li>
                    <span>Users&#39; personal data may be transferred to: entities related to the Administrator, its subcontractors, entities cooperating with the Administrator, e.g. companies servicing e-payments, companies providing courier / postal services, law firms.</span></li>
                    <li><span>Users&#39; personal data</span><span>&nbsp;will be</span>
                    <span>&nbsp;transferred outside the European Economic Area (EEA) to the cloud hosting service provider Amazon AWS located in United States.</span></li></ol>
                    <p><span><h3>User Rights</h3></span></p>
                    <ol>
                    <li><span>The Website User has the right to: access their personal data, rectify it, delete it, limit processing, transfer it, object to processing, withdraw consent at any time (which does not affect the lawfulness of processing based on consent before its consent) undo).</span></li>
                    <li><span>A notification about the User&#39;s withdrawal from the rights resulting from the above-mentioned rights should be sent to the following address: </span>
                    <span>contact@slad.ai</span></li><li><span>The administrator fulfills or refuses to fulfill the request immediately - within a maximum of one month from its receipt.</span></li>
                    <li><span>The user has the right to submit a complaint to the President of the Office for Personal Data Protection if he considers that the processing violates his rights and freedoms (GDPR).</span></li></ol>
                    <p><span><h3>Cookies</h3></span></p>
                    <ol><li><span>The Website collects information using cookies - session, permanent and third-party cookies.</span></li>
                    <li><span>Collecting cookies supports the correct provision of services on the Website and serves statistical purposes.</span></li>
                    <li><span>The user can specify the scope of access of cookies to his device in the browser settings.</span></li></ol>
                    <p><span><h3>Automated decision making and profiling</h3></span></p>
                    <ol><li><span>Users&#39; data may not be processed in an automated manner so that any decisions could be made against them.</span></li>
                    <li><span>Users&#39; data may be profiled in order to adjust the content and personalize the offer after their consent.</span></li></ol>
                    <p><span><h3>Final Provisions</h3></span></p>
                    <ol><li><span>The Administrator has the right to make changes to the Privacy Policy, but the Users&#39; rights will not be limited.</span></li>
                    <li><span>Information about the introduced changes will appear in the form of a message available on the Website.</span></li>
                    <li><span>In matters not covered by this Privacy Policy, the provisions of the GDPR and the provisions of Polish law shall apply.</span></li></ol>
                    
                    
				
				</div>
                
                </div>
            </div>
        </div>
    </div>
    



</div>`


export const blogHTML = /*html*/`
<div id="promo-container" class="container my-5">
    <div id="blogposts"></div>
</div>    
<div id="promo-container" class="container my-5 d-flex justify-content-center">

        
    
</div>
`
//<button id="blogpost-loadmore" type="button" class="btn btn-outline-dark fw-bold btn-lg" onclick="location.href='/?auth=blog&page=2';">Load more</button> 
export const blogpostHTML = /*html*/`

    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        
        <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
            <h1 id="blogpost-title" class="display-4 fw-bold lh-1">BLOG article title</h1>
            <p id="blogpost-lead" class="lead">Blog article lead in few sentences. Blog article lead in few sentences. Blog article lead in few sentences. Blog article lead in few sentences.</p>
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
            <button id="blogpost-button" type="button" class="btn btn-primary fw-bold btn-lg" onclick="location.href='/?auth=blog&id=1';">Read more</button>
            
            </div>
        </div>
        <div class="col-lg-6">
            <img src="./assets/images/slad5.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
        </div>
    
    </div>

`
export const blogpostshowHTML = /*html*/`

    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        
        <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
            <h1 id="blogpost-title" class="display-4 fw-bold lh-1">BLOG article title</h1>
            <p id="blogpost-lead" class="lead">Blog article lead in few sentences. Blog article lead in few sentences. Blog article lead in few sentences. Blog article lead in few sentences.</p>
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
            <button id="blogpost-button" type="button" class="btn btn-primary fw-bold btn-lg" onclick="location.href='/?auth=blog&id=1';">Read more</button>
            
            </div>
        </div>
        <div class="col-lg-6">
            <img src="./assets/images/slad5.png" class="grow img-fluid promo-img" alt="Bootstrap Themes"  loading="lazy">
        </div>
    
    </div>

`